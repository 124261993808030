<template>
  <Modal v-bind="props" ref="modalRef" :class-name="['modal--confirm', className].join(' ')">
    <template v-if="$slots.header" #header="slotProps">
      <slot name="header" v-bind="{ ...slotProps }" />
    </template>
    <template v-if="$slots.content" #content="slotProps">
      <slot name="content" v-bind="{ ...slotProps }" />
    </template>
    <template v-if="$slots.footer" #footer="slotProps">
      <slot name="footer" v-bind="{ ...slotProps }" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
  import { ref, type PropType } from 'vue';
  import type { VueNode } from '@/types/vue';
  import Modal from '@/components/Modal';

  defineOptions({
    name: 'Confirm',
  });

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: 'Confirm',
    },
    onOk: {
      type: Function as PropType<() => void | Promise<void>>,
      default: null,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    onCancel: {
      type: Function as PropType<() => void | Promise<void>>,
      default: null,
    },
    title: {
      type: [String, Object, Function] as PropType<VueNode | (() => VueNode)>,
      default: '',
    },
    content: {
      type: [String, Object, Function] as PropType<VueNode | (() => VueNode)>,
      default: '',
    },
  });

  const modalRef = ref();
  function close() {
    modalRef.value?.close();
  }
  defineExpose({ close });
</script>

<style scoped lang="scss">
  .confirm {
  }

  :deep(.modal) {
    .modal-header {
      .title {
        text-align: center;
      }
    }
  }
</style>
