export enum ERROR_CODE {
  REQUEST_ERROR = 50000,
  NETWORK_ERROR = 50001,
  TIMEOUT = 50002,
  REQUEST_ABORTED = 50003,
  REQUEST_DUPLICATE = 50004,
}

export const DEFAULT_ERROR_CODE = ERROR_CODE.REQUEST_ERROR;
export const SERVER_ERROR = 'Service exception. Please try again'; // 服务异常，请稍后重试;
export const UNKNOWN_ERROR = 'Unknown error'; // 未知错误

export const ERROR_MESSAGE: Record<string, string> = {
  [ERROR_CODE.REQUEST_ERROR]: 'Request failed. Please try again', // 请求失败，请稍后重试
  [ERROR_CODE.NETWORK_ERROR]: 'Network error. Please try again', //网络异常，请稍后重试
  [ERROR_CODE.TIMEOUT]: 'Request timeout. Please try again', // 请求超时，请稍后重试
  [ERROR_CODE.REQUEST_ABORTED]: 'Request aborted', // 请求被终止
  [ERROR_CODE.REQUEST_DUPLICATE]: 'Duplicate request', // 重复请求
};

export const HTTP_STATUS_ERROR: Record<string, string> = {
  400: 'Bad Request', // 请求无效
  401: 'Unauthorized', // 未授权
  403: 'Forbidden', // 禁止访问
  404: 'Not Found', // 无法找到
  405: 'Method Not Allowed', // 方法不被允许
  406: 'Not Acceptable', // 无法接受
  407: 'Proxy Authentication Required', // 需要代理身份验证
  408: 'Request Timeout', // 请求超时
  409: 'Conflict', // 指令冲突
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large', // 请求实体过大
  414: 'URI Too Long', // 所请求的URL地址过长
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error', // 内部服务器错误
  501: 'Not Implemented', // 未实现
  502: 'Bad Gateway', // 网关错误
  503: 'Service Unavailable', // 服务不可用
  504: 'Gateway Timeout', // 网关超时
  505: 'HTTP Version Not Supported',
  511: 'Network Authentication Required',
};

export const UNAUTHORIZED = 401;
export const SERVICE_EXCEPTION: Record<string, string> = {
  401: 'Unauthorized. Please login again', // 未授权，请重新登录
};
