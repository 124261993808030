export * from './type';
export * from './string';
export * from './dateTime';
export * from './url';
export * from './cookie';
export * from './platform';
export * from './validate';
export * from './dom';
export * from './media';
export * from './resourceLoader';
export * from './discode';
export * from './mediaQuery';

/**
 * 延迟等待
 * @param {number} time 延迟时间，毫秒
 */
export function sleep(time: number) {
  return new Promise<void>((resolve) => {
    setTimeout(function () {
      resolve();
    }, time);
  });
}

/**
 * 生成一个随机ID
 * @param prefix
 */
export function uuid(prefix = '') {
  const s: string[] = [];
  const hexDigits = '0123456789abcdef';
  const hexDigitsLen = hexDigits.length;
  for (let i = 0; i < 36; i++) {
    const index = Math.floor(Math.random() * hexDigitsLen);
    s[i] = hexDigits.slice(index, index + 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

  // @ts-ignore
  const index = (s[19] & 3) | 8;
  s[19] = hexDigits.slice(index, index + 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  return (prefix ? `${prefix}-` : '') + s.join('');
}

/**
 * 生成一个随机字符串
 * @param prefix
 */
export function randomString(len = 4, prefix = '') {
  const s: string[] = [];
  const hexDigits = '0123456789abcdefghijklmnopqrstuvwxyz';
  const hexDigitsLen = hexDigits.length;
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * hexDigitsLen);
    s[i] = hexDigits.slice(index, index + 1);
  }

  return (prefix ? `${prefix}-` : '') + s.join('');
}

/**
 * 解析json
 * @param {*} params
 */
export function jsonParse(params: any) {
  let result;

  if (typeof params === 'object') {
    result = params;
  } else {
    try {
      result = JSON.parse(params);
    } catch (e) {
      result = params;
      console.error(`invalid params: ${params}`);
    }
  }
  return result;
}

/**
 * 参数转换为json字符串
 * @param params
 * @returns
 */
export function jsonStringify(params: any) {
  let result;

  if (typeof params === 'string') {
    result = params;
  } else {
    try {
      result = JSON.stringify(params);
    } catch (e) {
      result = params;
      console.error(`invalid params: ${params}`);
    }
  }
  return result;
}

/**
 * 时间转换 秒转换为 00:00:00 格式
 * @param {number} seconds 时长，单位秒
 * @returns {string}
 */
export function secondsFormat(seconds: number) {
  seconds = Math.ceil(seconds);
  const min = Math.floor(seconds / 60);

  const h = Math.floor(min / 60);
  const m = Math.floor(min % 60);
  const s = Math.floor(seconds % 60);
  const arr = [String(m).padStart(2, '0'), String(s).padStart(2, '0')];
  if (h) {
    arr.unshift(String(h).padStart(2, '0'));
  }

  return arr.join(':');
}

/**
 * 字节单位转换
 * @param byte
 */
export function byteFormat(byte: number) {
  byte = Math.ceil(byte);
  let size = byte / 1024;
  let unit = 'KB';
  if (size > 1024) {
    size /= 1024;
    unit = 'MB';
  }
  size = parseFloat(size.toFixed(1));
  return { size, unit, show: `${size}${unit}` };
}

/**
 * 函数防抖
 * @param {function} func 执行函数
 * @param {number} wait 延迟时间，毫秒
 * @param {object} options 其他选项，{immediate: true}
 */
export function debounce(func: Function, wait = 0, options: { immediate?: boolean } = {}) {
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }

  const { immediate = true } = options;
  let timer: number | null = null;
  return function (...args: any[]) {
    if (immediate) {
      // @ts-ignore
      timer ? clearTimeout(timer) : func.apply(this, args);
      timer = window.setTimeout(() => {
        timer = null;
      }, wait);
    } else {
      timer && clearTimeout(timer);
      timer = window.setTimeout(() => {
        // @ts-ignore
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

/**
 * 函数节流
 * @param {function} func 执行函数
 * @param {number} wait 延迟时间，毫秒
 * @param {object} options 其他选项，{immediate: true}
 */
export function throttle(func: Function, wait = 0, options: { immediate?: boolean } = {}) {
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }

  const { immediate = true } = options;
  let timer: number | null = null;

  return function (...args: any[]) {
    if (timer) return;

    if (immediate) {
      // @ts-ignore
      func.apply(this, args);

      timer = window.setTimeout(() => {
        timer = null;
      }, wait);
    } else {
      timer = window.setTimeout(() => {
        // @ts-ignore
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

/**
 * 给一个异步函数增加竞态锁，防止并发执行
 * @param func
 * @returns
 */
export function lockFn<P extends any[] = any[], V extends any = any>(func: (...args: P) => Promise<V>) {
  let lock = false;

  return async (...args: P) => {
    if (lock) return;
    lock = true;
    try {
      const res = await func(...args);
      lock = false;
      return res;
    } catch (e) {
      lock = false;
      throw e;
    }
  };
}

/**
 * 比较两个版本号的大小
 * @param v1
 * @param v2
 * @returns {number} 如果返回1表示前者大于后者, -1表示小于，0表示相等
 */
export function compareVersion(v1: string, v2: string) {
  const v1Arr = v1.split('.');
  const v2Arr = v2.split('.');
  const len = Math.max(v1Arr.length, v2Arr.length);

  while (v1Arr.length < len) {
    v1Arr.push('0');
  }
  while (v2Arr.length < len) {
    v2Arr.push('0');
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1Arr[i]);
    const num2 = parseInt(v2Arr[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

/**
 * 概率随机数
 * @param {*} numList 备选数字列表
 * @param {*} percentList 百分比列表
 * @returns
 */
export function randomWithPercentList(numList: number[], percentList: number[]) {
  if (numList.length !== percentList.length) {
    throw new Error('numList and percentList must be equal in length');
  }

  const totalPercent = percentList.reduce((acc, percent) => acc + percent, 0);
  if (totalPercent !== 100) {
    throw new Error('The sum of the percentList must equal 100%');
  }

  // 生成一个 0 到 99 之间的随机数
  const randomValue = Math.floor(Math.random() * 100);

  // 根据百分比列表确定随机数所属的区间
  let cumulativePercent = 0;
  for (let i = 0; i < percentList.length; i++) {
    cumulativePercent += percentList[i];
    if (randomValue < cumulativePercent) {
      return numList[i]; // 返回对应的备选数字
    }
  }

  // 如果随机数不在百分比列表范围内，可能是由于舍入错误等原因，返回最后一个备选数字
  return numList[numList.length - 1];
}

/**
 * 随机整数
 * @param {number} min
 * @param {number} max
 * @param {boolean} leftClose
 * @param {boolean} rightClose
 * @returns
 */
export function random(min: number, max: number, leftClose = true, rightClose = true) {
  if (!leftClose) min += 1;
  if (!rightClose) max -= 1;
  if (min > max) throw new Error('random: not allow min>max');
  const num = Math.floor(Math.random() * (max - min + 1)) + min;

  return num;
}

/**
 * 随机浮点数
 * @param {number} min
 * @param {number} max
 * @param {boolean} leftClose
 * @param {boolean} rightClose
 * @returns
 */
export function randomFloat(min: number, max: number, leftClose = true, rightClose = true) {
  let num = random(min, max, leftClose, rightClose);
  if (num < max) {
    num += Math.random();
  }

  return num;
}

/**
 * 计算字符串的和
 * @param str
 * @returns
 */
export function sumString(str: string) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} config 字段配置
 * @param {*} config.id id字段 默认 'id'
 * @param {*} config.parentId 父节点字段 默认 'parentId'
 * @param {*} config.children 孩子节点字段 默认 'children'
 */
export function buildTree<N extends { [k: string]: any }>(
  data: N[],
  config = { id: 'id', parentId: 'parentId', children: 'children' },
) {
  const nodeMap: Record<string, N> = {};
  const tree: N[] = [];

  data.forEach((row) => {
    const id = row[config.id];
    nodeMap[id] = row;
  });

  data.forEach((node) => {
    const pid = node[config.parentId];
    const parentNode = nodeMap[pid];
    if (parentNode) {
      if (!parentNode[config.children]) {
        // @ts-ignore
        parentNode[config.children] = [] as N[];
      }
      parentNode[config.children].push(node);
    } else {
      tree.push(node);
    }
  });

  return tree;
}

/**
 * 深拷贝
 * @param {*} obj
 * @param {*} cacheMap
 * @returns
 */
export function deepClone(obj: any, cacheMap = new WeakMap()) {
  if (obj === null || typeof obj !== 'object') return obj;

  for (const constructor of [Number, String, Boolean, Date, RegExp]) {
    if (obj instanceof constructor) {
      // @ts-ignore
      return new obj.constructor(obj);
    }
  }
  // 函数暂不考虑，typeof obj !== 'object' 这个判断先把函数情况去除
  // if (typeof obj ==='function' ) return new Function(`return ${obj.toString()}`)();

  // 循环引用
  if (cacheMap.get(obj)) return cacheMap.get(obj);

  // 处理数组、普通对象、Set、Map等
  const res = new obj.constructor();
  cacheMap.set(obj, res);

  if (obj instanceof Map) {
    // Map
    obj.forEach((val, key) => {
      res.set(deepClone(val, cacheMap), deepClone(key, cacheMap));
    });
  } else if (obj instanceof Set) {
    // Set，Set结构没有key因此key值与value一样
    obj.forEach((val, key) => {
      res.add(deepClone(val, cacheMap));
    });
  } else {
    // 数组和对象 (Reflect.ownKeys 可以取到Symbol类型的key)
    Reflect.ownKeys(obj).forEach((key) => {
      res[key] = deepClone(obj[key], cacheMap);
    });
  }

  return res;
}

export function pathSlash(path: string) {
  return path.replace(/\\/g, '/');
}

export function pathTrim(path: string) {
  return path.replace(/\/+/g, '/');
}

/**
 * 替换URL路径中动态参数
 * @param urlPath
 * @param params
 */
export function generatePath(urlPath: string, params: Record<string, string | number>) {
  return urlPath.replace(/\/:[a-zA-Z]*/g, (match) => {
    return ('/' + (params[match.slice(2)] || '')) as string;
  });
}

/**
 *
 * @returns 判断是否支持webp
 */
let _isSupportWebp: boolean;
export function isSupportWebp() {
  if (_isSupportWebp === undefined && typeof window !== 'undefined') {
    _isSupportWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return _isSupportWebp;
}

/**
 * blob 生成 ObjectUrl
 * @param file
 * @returns
 */
export function createObjectUrl(file: Blob): string {
  return URL.createObjectURL(file);
}

/**
 * 销毁 ObjectUrl
 * @param url
 * @returns
 */
export function destroyObjectUrl(url: string) {
  URL.revokeObjectURL(url);
}

/**
 * vite 动态引入资源
 * @param assetPath 资源路径
 * @param webp 是否使用webp
 * @returns
 */
export function getAssetsUrl(assetPath: string, webp = false) {
  if (webp) {
    assetPath = assetPath.replace(/\.([^.]+)$/i, '.webp');
  }

  return new URL(`/src/assets/${assetPath}`, import.meta.url).href;
}
