/**
 * 解析URL
 * @param {string} url
 */
export function parseUrl(url: string) {
  // protocol:              ^([^:/?#]+:)?
  // hostname & port::   (?:([^/?#:]*)(?::([0-9]+))?)
  // pathname:              ([^?#]*)?
  // search:                (\?[^#]*)?
  // hash:                  (#.*)?

  const pattern = RegExp(/^([^:/?#]+:)?\/\/(?:([^/?#:]*)(?::([0-9]+))?)([^?#]*)?(\?[^#]*)?(#.*)?/);

  const matches = url.match(pattern) || [];

  const protocol = matches[1] || '';
  const hostname = `${matches[2] || ''}`;
  const port = matches[3] || '';
  const pathname = matches[4] || '';
  const search = matches[5] || '';
  const hash = matches[6] || '';
  const host = `${hostname}${port ? `:${port}` : ''}`;
  const origin = `${protocol}//${host}`;

  const urlInfo = {
    href: url,
    protocol,
    hostname,
    port,
    pathname,
    search,
    hash,
    host,
    origin,
  };

  return urlInfo;
}

/**
 * 获取search中参数
 * @param {string} search
 */
export function getSearchParams<D extends Record<string, any>>(search: string): D {
  const params = {} as D;
  if (!search) return params;

  const queryString = search.slice(1);
  if (!queryString) return params;

  const paramStrList = queryString.split('&');
  paramStrList.forEach((paramStr) => {
    const kv = paramStr.split('=', 2);
    if (kv.length !== 2 || !kv[0]) {
      console.warn(`invalid query params: ${kv}`);
      return;
    }
    const k = decodeURIComponent(kv[0]) as keyof D;
    const val = decodeURIComponent(kv[1]) as any;
    params[k] = val;
  });
  return params;
}

/**
 * 获取hash中参数
 * @param {string} hash
 */
export function getHashParams(hash: string) {
  const params: Record<string, any> = {};
  const index = hash.indexOf('?');
  if (index === -1) return params;
  const search = hash.slice(index);

  return getSearchParams(search);
}

/**
 * 获取url中参数
 * @param {string} url
 */
export function getQueryParams<R extends Record<string, any>>(url: string): R {
  const urlInfo = parseUrl(url);
  const search = urlInfo.search;

  return getSearchParams<R>(search);
}

/**
 * 将参数连接到指定URL后面
 * @param {string} url
 * @param {any} params 一个map，包含要连接的参数
 */
export function joinQueryParams(url: string, params: Record<string, any>) {
  if (!url || !params) {
    return url;
  }
  const oriParams = getQueryParams(url);

  // 用新参数覆盖老map
  const targetParams: Record<string, any> = Object.assign({}, oriParams, params);

  // 整理成为数组
  const tempArr: string[] = [];
  Object.keys(targetParams).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(targetParams, key)) {
      const value = targetParams[key];
      tempArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });

  const urlInfo = parseUrl(url);

  url = urlInfo.origin + urlInfo.pathname;

  // 连接参数列表到url
  if (tempArr.length) {
    url = `${url}?${tempArr.join('&')}`;
  }
  // 连接hash列表到url
  if (urlInfo.hash) {
    url = url + urlInfo.hash;
  }
  return url;
}

/**
 * 规整url
 * @param {string} url
 */
export function trimUrl(url: string) {
  // 去除多余的"/"
  url = url.replace(/([^:/]|^)\/{2,}/g, '$1/');
  // 处理"/xx/../"
  url = url.replace(/\/[^/]+?\/\.\.\//g, '/');

  return url;
}

/**
 * 替换url中的host
 * @param {string} url
 * @param {string} host，要添加的host
 * @param {boolean} forced，是否强制替换（默认false）
 */
export function wrapHost(url: string, host: string, forced = false) {
  if (!url || !host) {
    return url;
  }

  const urlInfo = parseUrl(url);
  if (!urlInfo.origin) {
    url = `${host}/${url}`;
  } else if (forced) {
    url.replace(urlInfo.origin, host);
  }

  return url;
}

/**
 * 判断是否是绝对路径
 * @param url
 * @returns
 */
export function isAbsoluteURL(url: string) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

/**
 * 相对路径拼接host
 * @param baseURL
 * @param relativeURL
 * @returns
 */
export function combineURL(baseURL: string, relativeURL: string) {
  return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL;
}

/**
 * 获取url完整路径
 * @param baseURL
 * @param url
 * @returns
 */
export function getFullPath(url: string, baseURL?: string) {
  if (baseURL && !isAbsoluteURL(url)) {
    return combineURL(baseURL, url);
  }
  return url;
}
