/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import type { App } from 'vue';
import hasRole from './permission/hasRole';
import hasPermission from './permission/hasPermission';
import debounce from './common/debounce';

export function setupDirectives(app: App) {
  app.directive('hasRole', hasRole);
  app.directive('hasPermission', hasPermission);
  app.directive('debounce', debounce);
}
