<template>
  <div v-show="open" ref="overlayRef" :class="['overlay', className, { blur: blur }]" :style="style">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import type { PropType, CSSProperties } from 'vue';
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
  import { updateCSS, removeCSS } from '@/utils';

  defineOptions({
    name: 'Overlay',
  });

  const emit = defineEmits(['clickOverlay', 'update:open']);

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: undefined,
    },
    style: {
      type: Object as PropType<CSSProperties>,
      default: undefined,
    },
  });

  const overlayRef = ref();
  const id = ref('overlay-' + new Date().getTime());

  watch(
    () => props.open,
    (val) => {
      if (val) {
        updateCSS('html body {overflow: hidden;}', id.value);
      } else {
        removeCSS(id.value);
      }
    },
    { immediate: true },
  );

  function handleClickOverlay(evt: MouseEvent | TouchEvent) {
    emit('clickOverlay');
    if (props.maskClosable) {
      emit('update:open', false);
    }
  }

  onMounted(() => {
    overlayRef.value.addEventListener('click', handleClickOverlay);
  });
  onBeforeUnmount(() => {
    removeCSS(id.value);
    overlayRef.value.removeEventListener('click', handleClickOverlay);
  });
</script>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0 0 0 / 50%);

    &.blur {
      backdrop-filter: blur(10px);
    }
  }
</style>
