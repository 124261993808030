import { catchError } from '@/utils/error';
import Tracker from '@/lib/Tracker';
import refreshRem from '@/utils/flexible';

export default class Application {
  appName: string;
  version: string;
  bootstrap: () => Promise<any> = async () => {};
  tracker = Tracker;

  constructor(config: { appName?: string; version?: string } = {}, bootstrap?: () => Promise<any>) {
    const { appName = __APP_NAME__, version = __APP_VERSION__ } = config || {};
    this.appName = appName;
    this.version = version;
    if (bootstrap) {
      this.bootstrap = bootstrap;
    }

    this.tracker.initialize();
    window.sendEvent = (event: string, data?: Record<string, any>) => this.sendEvent(event, data);

    this._init();
    this.refreshRem();
    this.fixIOS100vh();
  }

  async onReady() {
    this.log('App is running');
    const res = await this.bootstrap();
    this.log('App is ready', res);
    return res;
  }

  _init() {
    catchError();
  }

  log(message: string, ...args: any[]) {
    if (!__DEBUG__) {
      return;
    }
    console.log(
      `%c[${this.appName}:${__ENV__}]: %c${message} ${args.length ? JSON.stringify(args) : ''}`,
      'color:green;font-size:14px',
      'color:blue;',
    );
  }

  fixIOS100vh() {
    // if (!isIos()) return;
    function setViewportHeightProperty(doc: HTMLElement) {
      function handleResize() {
        let prevClientHeight: number | undefined;
        const customVar = '--vh';
        const clientHeight = doc.clientHeight;
        if (clientHeight === prevClientHeight) return;
        requestAnimationFrame(function updateViewportHeight() {
          doc.style.setProperty(customVar, clientHeight * 0.01 + 'px');
          prevClientHeight = clientHeight;
        });
      }
      handleResize();
      return handleResize;
    }
    window.addEventListener('resize', setViewportHeightProperty(document.documentElement));
  }

  refreshRem() {
    refreshRem({ uiWidth: 1920, minWidth: 1200, maxWidth: 1920 });
  }

  sendEvent(event: string, data?: Record<string, any>) {
    this.tracker.sendEvent(event, data);
  }
}
