import { createApp } from 'vue';
import '@/styles/index.scss';
import Application from '@/Application';
import App from '@/App.vue';
import { setupGlobalApi } from '@/globalApi';
import { setupDirectives } from '@/directives';
import store, { useAppStore } from '@/store';
import router from '@/router';
import { parseError, onError } from '@/utils/error';

// 兼容 hash 路由
const hashPath = location.hash.replace(/\?.*/, '');
if (['#/index', '#/create', '#/result'].includes(hashPath)) {
  location.replace(hashPath === '#/index' ? '/create' : hashPath.replace(/^#/, '') + location.search);
}

const application = new Application();

application.onReady().then(() => {
  const app = createApp(App);
  app.config.errorHandler = (err, instance, info) => {
    console.error(err);

    const error = err as Error;
    try {
      const errorInfo = parseError(error);

      onError(errorInfo.message, errorInfo.file, errorInfo.lineno, errorInfo.colno, error);
    } catch (e) {
      console.error(`errorHandler: ${error.toString()}`);
    }
  };

  setupGlobalApi(app);
  setupDirectives(app);

  app.use(store);
  app.use(router);
  useAppStore()
    .bootStrap()
    .then(() => {
      app.mount('#app');
    });
});

if (__DEBUG__) {
  window.$app = application;
}
