import type { RouteMeta } from 'vue-router';

type DynamicRoute = {
  path: string;
  component: string;
  meta?: RouteMeta;
  children?: DynamicRoute[];
};

// 动态路由，基于用户权限动态加载
const routes: DynamicRoute[] = [];

export default routes;
