import type { ConfigProviderProps } from 'ant-design-vue/es';
import { px2remTransformer } from 'ant-design-vue/es';

type ThemeConfig = ConfigProviderProps['theme'];

const theme: ThemeConfig = {
  hashed: false,
  components: {
    Dropdown: {
      controlItemBgHover: '#F7F2FF',
      controlItemBgActiveHover: '#994EFF',
      controlItemBgActive: '#994EFF',
    },
  },
};

const px2rem = px2remTransformer({
  rootValue: 192, // 32px = 1rem; @default 16
});

export { px2rem };

export default theme;
