import type { RequestOptions } from '@/lib/Request/type';
import { request } from '@/utils/request';
import type { Result } from './type';
import type { User, Session } from './supabase';

type UserInfoResult = Result<User>;
export async function getUserInfo(options?: RequestOptions) {
  return request.get<UserInfoResult>('/auth/v1/user', { ...options }).then((res) => {
    return res;
  });
}

type LoginInfo = {
  email: string;
  password: string;
};
type RegisterResult = Result<User>;
export async function register(data: LoginInfo, options?: RequestOptions) {
  const { email, password } = data;
  return request
    .post<RegisterResult>(
      '/auth/v1/signup',
      { email, password },
      { loadingOptions: { show: true, delay: 0 }, ...options },
    )
    .then((res) => {
      return res;
    });
}

type LoginResult = Result<Omit<Session, 'user'>>;
export async function login(data: LoginInfo, options?: RequestOptions) {
  const { email, password } = data;

  // {
  //   return supabase.auth
  //     .signInWithPassword({
  //       email,
  //       password,
  //     })
  //     .then((res) => {
  //       return { code: 0, message: '', data: res.data.session };
  //     });
  // }

  return request
    .post<LoginResult>(
      '/auth/v1/token?grant_type=password',
      {
        email: email,
        password: password,
      },
      {
        loadingOptions: { show: true, delay: 0 },
        ...options,
      },
    )
    .then((res) => {
      return res;
    });
}

export async function refreshToken(refreshToken: string) {
  return request
    .post<LoginResult>(
      '/auth/v1/token?grant_type=refresh_token',
      { refresh_token: refreshToken },
      { withToken: false, loadingOptions: { show: true, delay: 0 } },
    )
    .then((res) => {
      return res;
    });
}

export async function logout(options?: RequestOptions): Promise<void> {
  return request
    .post('/auth/v1/logout?scope=global', {}, { loadingOptions: { show: true, delay: 0 }, ...options })
    .catch((error) => {
      console.error(error);
    });
}
