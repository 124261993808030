/**
 * v-debounce 防抖，v-debounce="()=>{}", v-debounce="{handler: () => {}, wait: 3000}"
 */
import type { ObjectDirective } from 'vue';
import { debounce, isObject } from '@/utils';

const vDebounce: ObjectDirective = {
  mounted(el, { value }) {
    const { handler, wait = 1000 }: { handler: () => void; wait: number } =
      isObject(value) && value.handler ? value : { handler: value };

    const debounceFn = debounce(handler, wait);

    el.addEventListener('click', debounceFn);
  },
};

export default vDebounce;
