export default function refreshRem(
  options: { uiWidth: number; minWidth?: number; maxWidth?: number },
  autoRefresh = true,
) {
  const { uiWidth, minWidth = 0, maxWidth = 0 } = options || {};
  const docEl = document.documentElement;
  const dpr = window.devicePixelRatio || 1;

  function getScale() {
    const baseSize = uiWidth / 10;
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

    minWidth > 0 && (clientWidth = Math.max(clientWidth, minWidth));
    maxWidth > 0 && (clientWidth = Math.min(clientWidth, maxWidth));

    // 当前页面宽度相缩放比例
    const scale = clientWidth / uiWidth;

    return { scale, baseSize };
  }

  function setRemUnit() {
    const { baseSize, scale } = getScale();
    const rem = baseSize * scale;
    docEl.style.fontSize = rem + 'px';

    document.dispatchEvent(
      new CustomEvent<{ scale: number; baseSize: number }>('onRefreshRem', {
        detail: { scale, baseSize },
        bubbles: true,
        cancelable: false,
      }),
    );
  }

  setRemUnit();

  if (autoRefresh) {
    window.addEventListener('resize', setRemUnit);
    window.addEventListener('pageshow', function (e) {
      if (e.persisted) {
        setRemUnit();
      }
    });
  }

  // detect 0.5px supports
  if (dpr >= 2) {
    const fakeBody = document.createElement('body');
    const testElement = document.createElement('div');
    testElement.style.border = '.5px solid transparent';
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines');
    }
    docEl.removeChild(fakeBody);
  }

  return setRemUnit;
}
