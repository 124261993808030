/**
 * 获取数据类型
 * @param val
 */
export function getType(val: any) {
  const type = typeof val;
  return (type === 'object' ? Object.prototype.toString.call(val).slice(8, -1) : type).toLowerCase();
}

/**
 * 判断是否是函数
 * @param val
 */
export function isFunction(val: any) {
  return getType(val) === 'function';
}

/**
 * 判断是否是对象
 * @param val
 */
export function isObject(val: any) {
  return val && getType(val) === 'object';
}

/**
 * 判断是否是数组
 * @param val
 */
export function isArray(val: any) {
  return getType(val) === 'array';
}

/**
 * 判断是否是数字
 * @param val
 */
export function isNumber(val: any) {
  return getType(val) === 'number';
}

/**
 * 判断是否是字符串
 * @param val
 */
export function isString(val: any) {
  return getType(val) === 'string';
}

/**
 * 判断是否空对象
 * @param obj
 */
export function isEmptyObject(obj: any) {
  return isObject(obj) && Object.keys(obj).length < 1;
}
