import type { RouteLocationNormalized, RouteRecordNormalized } from 'vue-router';
import routeComponents from '@/router/routeComponents';

const modules = import.meta.glob(['@/pages/**/!(components)/*.{vue,jsx,tsx}', '@/pages/*.{vue,jsx,tsx}']);
const pagesMap = Object.keys(modules).reduce(
  function (obj, key) {
    const name = key.split('pages/')[1].slice(0, -4);
    obj[name] = modules[key];
    return obj;
  },
  {} as Record<string, any>,
);

export function dynamicLoadPage(pagePath: string) {
  // if (__DEV__) {
  //   return (resolve) => require([`@/pages/${pagePath}`], resolve);
  // } else {
  //   return () => import(`@/pages/${pagePath}`);
  // }

  const pageComponent = routeComponents[pagePath] || pagesMap[pagePath];
  return pageComponent;
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route;
  const { matched, ...options } = route;
  return {
    ...options,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path,
        }))
      : undefined) as RouteRecordNormalized[],
  };
}
