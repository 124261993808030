import type { App } from 'vue';
import { $auth } from './auth';
import { $loading } from './loading';
import { $modal } from './modal';
import { $message } from './message';
import { $download } from './download';

export { $auth, $modal, $message, $loading, $download };

export function setupGlobalApi(app: App) {
  app.config.globalProperties.$auth = $auth;
  app.config.globalProperties.$modal = $modal;
  app.config.globalProperties.$message = $message;
  app.config.globalProperties.$loading = $loading.loading;
  app.config.globalProperties.$processLoading = $loading.processLoading;
  app.config.globalProperties.$download = $download;
}
