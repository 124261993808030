import { defineStore } from 'pinia';
import logo from '@/assets/logo/logo.svg?url';
import { getScreenNameByMatchMedia } from '@/utils/mediaQuery';
import useUserStore from './user';

const useAppStore = defineStore('app', {
  persist: {
    paths: ['theme', 'lang'],
  },
  state: () => ({
    siteTitle: __SITE_TITLE__,
    theme: 'dark',
    lang: 'en-US',
    logo: logo,
    dynamicTitle: true,
    mediaName: getScreenNameByMatchMedia(),
  }),
  actions: {
    async bootStrap() {
      const { bootstrap: userBootstrap } = useUserStore();
      await userBootstrap();
    },
    setPageTitle(pageTitle = '', replace = false) {
      const title = pageTitle
        ? replace || pageTitle.startsWith(this.siteTitle)
          ? pageTitle
          : `${this.siteTitle} - ${pageTitle}`
        : this.siteTitle;

      if (!this.dynamicTitle) return;

      document.title = title;
    },
    setMediaQuery(mediaName: MediaType) {
      this.mediaName = mediaName;
    },
  },
  getters: {
    isMobile(state): boolean {
      const isMobile = ['xs', 'sm'].indexOf(state.mediaName) !== -1;
      return isMobile;
    },
  },
});

export default useAppStore;
