<template>
  <div v-show="open" :class="['loading', 'loading-mask', { 'is-delay': isDelay }]" :style="maskStyle">
    <div :class="['loading-main', className]">
      <div class="spinner"><LoadingOutlined /></div>
      <p v-if="text" class="text">{{ text }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, onUnmounted, type PropType, type CSSProperties } from 'vue';
  import { LoadingOutlined } from '@ant-design/icons-vue';
  import { updateCSS, removeCSS } from '@/utils';

  defineOptions({
    name: 'Loading',
  });

  const emit = defineEmits(['update:open']);

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Loading...',
    },
    lock: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 500,
    },
    className: {
      type: String,
      default: '',
    },
    maskStyle: {
      type: Object as PropType<CSSProperties>,
      default: undefined,
    },
    closed: {
      type: Function as PropType<() => void>,
      default: null,
    },
    onClosed: {
      type: Function as PropType<() => void>,
      default: null,
    },
  });

  const id = ref('overlay-' + new Date().getTime());
  const open = ref(props.open);
  const isDelay = ref<boolean>(true);
  const text = ref<string>(props.text);

  let delayTimer: number | null;
  watch(
    () => props.open,
    (val) => {
      if (val) {
        props.lock && updateCSS('html body {overflow: hidden;}', id.value);

        if (props.delay > 0) {
          delayTimer = window.setTimeout(() => {
            isDelay.value = false;
            delayTimer = null;
          }, props.delay);
        } else {
          isDelay.value = false;
        }
      } else {
        removeCSS(id.value);

        isDelay.value = false;
        delayTimer && clearTimeout(delayTimer);
      }
    },
    { immediate: true },
  );

  function updateText(val: string) {
    text.value = val;
  }

  function close() {
    open.value = false;
    emit('update:open', false);
    props.onClosed?.();
  }

  onUnmounted(() => {
    if (props.open) {
      close();
    }
  });

  defineExpose({ close, updateText });
</script>

<style scoped lang="scss">
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;

    &.loading-mask {
      background-color: rgb(0 0 0 / 30%);
    }

    &.is-delay {
      &.loading-mask {
        background-color: transparent;
      }

      .loading-main {
        display: none;
      }
    }

    .loading-main {
      position: absolute;
      top: 50%;
      left: 50%;
      /* prettier-ignore */
      font-size: 16PX;
      transform: translateX(-50%) translateY(-50%);

      .spinner {
        font-size: 2em;
        color: var(--theme-color-loading, #666666);
        text-align: center;
      }

      .text {
        margin-top: 6px;
        color: var(--theme-color-text, #333333);
      }
    }
  }
</style>
