import Request from '@/lib/Request';
import { $loading, $auth } from '@/globalApi';

const request = new Request({
  baseURL: __API_HOST__,
  withCredentials: false,
  timeout: 30000,
  headers: {
    apikey: __SUPABASE_KEY__,
  },
  getTokenHandler: () => {
    return $auth.getToken();
  },
  beforeRequestHandler(requestConfig) {},
  beforeResponseHandler(response, isError) {
    const responseData = response.data;
    if (responseData && typeof responseData === 'object') {
      if (responseData.code !== undefined) {
        responseData.message =
          responseData.message || responseData.msg || responseData.error_description || responseData.error;
      } else if (responseData.error_description || responseData.error) {
        responseData.code = -1;
        responseData.message =
          responseData.message || responseData.msg || responseData.error_description || responseData.error;
      } else {
        response.data = { code: 0, message: '', data: responseData };
      }
    }
  },
  loadingHandler(show, loadingOptions) {
    if (show) {
      $loading.showLoading(loadingOptions);
      return;
    }
    $loading.closeLoading();
  },
  errorHandler(errorInfo) {
    if (__DEBUG__) {
      console.log(errorInfo);
    }
  },
  unauthorizedHandler(errorInfo) {
    // 退出接口不做处理
    if (errorInfo.url.indexOf('/logout') > -1) {
      return;
    }
    $auth.goLogin();
  },
});

export { request };
