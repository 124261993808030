<template>
  <a-config-provider :wave="{ disabled: true }" :theme="theme">
    <a-style-provider hash-priority="high" :transformers="[]">
      <RouterView />
    </a-style-provider>
  </a-config-provider>
</template>

<script setup lang="ts">
  import { isSupportWebp } from '@/utils';
  import theme from '@/theme/antd';

  defineOptions({
    name: 'App',
  });

  if (isSupportWebp()) {
    document.documentElement.classList.add('webp');
  }
</script>
