import raf from './_utils/raf';
import { easeInOutCubic } from './_utils/easings';

export function isWindow(element: any | Window): element is Window {
  return element === window;
}

export function isElement(node: Element) {
  const ELEMENT_NODE_TYPE = 1;
  return node.nodeType === ELEMENT_NODE_TYPE;
}

/**
 * 将html字符串转换成dom对象
 * @param {string} html
 */
export function html2dom(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.childNodes;
}

export function getClassList(el: HTMLElement) {
  if (!el) {
    throw new Error('el not a HTMLElement');
  }
  if (el.classList) return Array.from(el.classList);

  const classList = el.className.split(/\s+/);
  return classList;
}

/**
 *
 * @param {*} el
 * @param {string} className
 * @returns
 */
export function hasClass(el: HTMLElement, className: string) {
  const classList = getClassList(el);
  return classList.indexOf(className) !== -1;
}

/**
 *
 * @param {*} el
 * @param {string | array} classList
 * @returns
 */
export function addClass(el: HTMLElement, classList: string | string[]) {
  if (!Array.isArray(classList)) {
    classList = [classList];
  }
  const _classList = getClassList(el);
  classList = classList.filter((item) => _classList.indexOf(item) === -1);
  _classList.push(...classList);

  el.className = _classList.join(' ');
}

/**
 *
 * @param {*} el
 * @param {string | array} classList
 * @returns
 */
export function removeClass(el: HTMLElement, classList: string | string[]) {
  if (!Array.isArray(classList)) {
    classList = [classList];
  }

  let _classList = getClassList(el);
  _classList = _classList.filter((item) => classList.indexOf(item) === -1);

  el.className = _classList.join(' ');
}

/**
 *
 * @param {*} el
 * @param {string} className
 * @returns
 */
export function toggleClass(el: HTMLElement, className: string) {
  if (hasClass(el, className)) {
    removeClass(el, className);
    return;
  }
  addClass(el, className);
}

/**
 * 判断元素是否可见
 * @param el
 * @returns
 */
export function isElementVisible(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
    return false;
  }

  return true;
}

export function getScrollParent(el: Element, root: Window | HTMLElement | null = window): Window | Element | null {
  let node = el;
  const overflowStylePatterns = ['scroll', 'auto', 'overlay'];

  while (node && node !== root && isElement(node)) {
    if (node === document.body) {
      return root;
    }
    const { overflowY } = window.getComputedStyle(node);
    if (overflowStylePatterns.includes(overflowY)) {
      return node;
    }
    node = node.parentNode as Element;
  }
  return root;
}

function getScroll(target: HTMLElement | Window | Document | null, top = true): number {
  if (typeof window === 'undefined') {
    return 0;
  }
  const method = top ? 'scrollTop' : 'scrollLeft';
  let result = 0;
  if (isWindow(target)) {
    result = target[top ? 'pageYOffset' : 'pageXOffset'];
  } else if (target instanceof Document) {
    result = target.documentElement[method];
  } else if (target instanceof HTMLElement) {
    result = target[method];
  } else if (target) {
    // According to the type inference, the `target` is `never` type.
    // Since we configured the loose mode type checking, and supports mocking the target with such shape below::
    //    `{ documentElement: { scrollLeft: 200, scrollTop: 400 } }`,
    //    the program may falls into this branch.
    // Check the corresponding tests for details. Don't sure what is the real scenario this happens.
    result = target[method];
  }

  if (target && !isWindow(target) && typeof result !== 'number') {
    result = ((target.ownerDocument ?? target) as any).documentElement?.[method];
  }
  return result;
}

interface ScrollToOptions {
  /** Scroll container, default as window */
  getContainer?: () => HTMLElement | Window | Document;
  /** Scroll end callback */
  callback?: () => any;
  /** Animation duration, default as 450 */
  duration?: number;
}

export function scrollTo(y: number, options: ScrollToOptions = {}) {
  const { getContainer = () => window, callback, duration = 450 } = options;
  const container = getContainer();
  const scrollTop = getScroll(container);
  const startTime = Date.now();

  const frameFunc = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;
    const nextScrollTop = easeInOutCubic(time > duration ? duration : time, scrollTop, y, duration);
    if (isWindow(container)) {
      (container as Window).scrollTo(window.pageXOffset, nextScrollTop);
    } else if (container instanceof Document || container.constructor.name === 'HTMLDocument') {
      (container as Document).documentElement.scrollTop = nextScrollTop;
    } else {
      (container as HTMLElement).scrollTop = nextScrollTop;
    }
    if (time < duration) {
      raf(frameFunc);
    } else if (typeof callback === 'function') {
      callback();
    }
  };
  raf(frameFunc);
}

function getStyleContainer() {
  const container = document.head || document.body;
  return container;
}
function findStyleNode(id: string) {
  const container = getStyleContainer();
  const styleList = Array.from(container.children).filter(function (node) {
    return node.tagName === 'STYLE';
  });

  return styleList.find(function (node) {
    return node.getAttribute('id') === id;
  });
}

export function updateCSS(css: string, id: string) {
  const existNode = findStyleNode(id);
  if (existNode) {
    if (existNode.innerHTML !== css) {
      existNode.innerHTML = css;
    }
    return existNode;
  }

  const container = getStyleContainer();
  const styleNode = document.createElement('style');
  styleNode.setAttribute('id', id);
  styleNode.innerHTML = css;

  container.appendChild(styleNode);

  return styleNode;
}

export function removeCSS(id: string) {
  const existNode = findStyleNode(id);
  if (existNode) {
    const container = getStyleContainer();
    container.removeChild(existNode);
  }
}
