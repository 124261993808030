import { v4 as uuidV4 } from 'uuid';
import FileSaver from 'file-saver';

const { saveAs } = FileSaver;

const mimeTypeMap: Record<string, string | string[]> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
  png: 'image/png',
  svg: ['image/svg', 'image/svg+xml'],
  heic: 'image/heic',
  heif: 'image/heic',
  mp3: 'audio/mp3',
  wav: 'audio/wav',
  mp4: 'video/mp4',
  wov: 'video/quicktime',
  webm: 'video/webm',
  pdf: 'application/pdf',
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
};

export function getMimeTypeByExt(ext: string | string[]) {
  if (!Array.isArray(ext)) {
    ext = [ext];
  }
  return ext.map((e) => mimeTypeMap[e.toLowerCase()]).flat();
}

/**
 * 获取文件后缀
 * @param filename
 */
export function getFileExtension(filename: string) {
  const index = filename.lastIndexOf('.');
  if (index < 0) {
    return '';
  }

  const ext = filename.slice(index + 1);
  return ext;
}

/**
 * 获取Base64对应的 MIMETYPE
 *
 * @param base64 - Base64.
 * @returns - MIME type.
 */
export function getMimeTypeByBase64(base64: string) {
  const base64MimeRegex = /^data:(.+);base64,/;

  return base64.match(base64MimeRegex)?.slice(1, 2).pop();
}

/**
 * 随机文件名
 * @param fileName
 * @param random
 * @returns
 */
export function getRandomFilename(fileName: string, random = false) {
  const ext = getFileExtension(fileName);
  return (random ? uuidV4() : new Date().getTime()) + (ext ? '.' + ext : '');
}

/**
 * Base64转换为Blob
 *
 * @param base64 - Base64.
 * @returns - Blob.
 */
export async function base64ToBlob(base64: string): Promise<Blob> {
  const response = await fetch(base64);
  let blob = await response.blob();
  const mimeType = getMimeTypeByBase64(base64);
  if (mimeType) {
    // https://stackoverflow.com/a/50875615
    blob = new Blob([blob], { type: mimeType });
  }
  return blob;
}

/**
 * Base64转换为File
 *
 * @param base64 - Base64.
 * @param filename - Filename.
 * @param options - Options.
 * @returns - File.
 */
export async function base64ToFile(base64: string, filename: string, options?: FilePropertyBag): Promise<File> {
  const response = await fetch(base64);
  const blob = await response.blob();
  const mimeType = getMimeTypeByBase64(base64);
  return new File([blob], filename, { type: mimeType, ...options });
}

/**
 * blob转换为Base64
 *
 * @param blob - Blob.
 * @returns - Base64.
 */
async function blobToBase64Browser(blob: Blob): Promise<string> {
  enum Event {
    error = 'error',
    loadend = 'loadend',
  }

  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      const handleLoadend = () => {
        resolve(reader.result as string);
        reader.removeEventListener(Event.loadend, handleLoadend);
      };

      reader.addEventListener(Event.loadend, handleLoadend);

      const handleError = () => {
        reject(reader.error);
        reader.removeEventListener(Event.error, handleError);
      };

      reader.addEventListener(Event.error, handleError);

      reader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * blob转换为Base64
 *
 * @param blob - Blob.
 * @returns - Base64.
 */
async function blobToBase64Node(blob: Blob): Promise<string> {
  const defaultMimeType = 'application/octet-stream';
  const buffer = Buffer.from(await blob.text());
  const mimeType = blob.type || defaultMimeType;
  return `data:${mimeType};base64,${buffer.toString('base64')}`;
}

/**
 * blob转换为Base64
 *
 * @param blob - Blob.
 * @returns - Base64.
 */
export async function blobToBase64(blob: Blob): Promise<string> {
  return typeof FileReader === 'function' ? blobToBase64Browser(blob) : blobToBase64Node(blob);
}

export function download(url: string, filename = '') {
  if (!filename) {
    filename = url.slice(url.lastIndexOf('/') + 1) || String(new Date().getTime());
  }
  filename = decodeURI(filename);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.target = '_blank';
  a.click();
}

export function saveFile(data: string | Blob, filename = '', options?: FileSaver.FileSaverOptions) {
  if (!filename) {
    filename =
      (typeof data === 'string' ? data.slice(data.lastIndexOf('/') + 1) : (data as any).name) ||
      String(new Date().getTime());
  }

  filename = decodeURI(filename);

  saveAs(data, filename, options);
}
