<template>
  <Overlay :open="open" :class-name="maskClassName" :style="maskStyle" @clickOverlay="handleOnClickOverlay">
    <div :class="['modal', className]">
      <button v-if="closable" class="btn__close" @click="handleCancel"><SvgIcon name="close" /></button>

      <div class="modal-header">
        <slot name="header">
          <div v-if="typeof title === 'string'" class="title">{{ title }}</div>
          <component :is="title" v-else />
        </slot>
      </div>

      <div class="modal-body">
        <slot>
          <div v-if="typeof content === 'string'" class="content">{{ content }}</div>
          <component :is="content" v-else />
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <button v-if="!hideCancelButton" class="btn__cancel" @click="handleCancel">{{ cancelText }}</button>
          <button class="btn__ok" @click="handleOk">{{ okText }}</button>
        </slot>
      </div>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
  import { ref, watch, onUnmounted, type PropType, type CSSProperties } from 'vue';
  import type { VueNode } from '@/types/vue';

  defineOptions({
    name: 'Modal',
  });

  const emit = defineEmits(['update:open']);

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, Object, Function] as PropType<VueNode | (() => VueNode)>,
      default: '',
    },
    content: {
      type: [String, Object, Function] as PropType<VueNode | (() => VueNode)>,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    maskClassName: {
      type: String,
      default: undefined,
    },
    maskStyle: {
      type: Object as PropType<CSSProperties>,
      default: undefined,
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: 'Confirm',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    onOk: {
      type: Function as PropType<() => void | Promise<void>>,
      default: null,
    },
    onCancel: {
      type: Function as PropType<() => void | Promise<void>>,
      default: null,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    onClosed: {
      type: Function as PropType<() => void>,
      default: null,
    },
  });

  const open = ref(props.open);

  watch(
    () => props.open,
    (value) => {
      if (open.value === props.open) return;

      open.value = value;
    },
    { immediate: true },
  );

  function close() {
    open.value = false;
    emit('update:open', false);
    props.onClosed?.();
  }

  function handleOnClickOverlay() {
    props.maskClosable && handleCancel();
  }

  async function handleOk() {
    await props.onOk?.();
    close();
  }

  async function handleCancel() {
    await props.onCancel?.();
    close();
  }

  onUnmounted(() => {
    if (props.open) {
      close();
    }
  });

  defineExpose({ close });
</script>

<style scoped lang="scss">
  .modal {
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block;
    min-width: 335px;
    max-width: 90%;
    padding: 20px 24px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.25;
    color: #333333;
    background: #ffffff;
    border-radius: 8px;
    transform: translateX(-50%) translateY(-50%);

    .btn__close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
    }

    .modal-header {
      font-size: 18px;

      .title {
        text-align: center;
      }
    }

    .modal-body {
      margin-top: 10px;
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      button {
        height: 32px;
        padding: 0 16px;
        line-height: 32px;
        border-radius: 6px;

        & + button {
          margin-left: 16px;
        }

        &.btn__cancel {
          color: #333333;
          border: 1px solid var(--theme-color-border, #dcdcdc);
        }

        &.btn__ok {
          @extend %button-bg;
          color: #ffffff;
          background-color: var(--theme-color-primary, #1677ff);
        }
      }
    }
  }
</style>
