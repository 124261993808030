import {
  Service as Loading,
  type ServiceOptions as LoadingOptions,
  type ServiceInstance as LoadingInstance,
} from '@/components/Loading';
import { sleep } from '@/utils';

const loadingText = 'Loading...';

function loading(options = {} as LoadingOptions) {
  return Loading({ text: loadingText, ...options });
}

type ProcessLoading = {
  $updateText: (text: string, clearTimer?: boolean) => void;
  $finished: () => Promise<void>;
} & LoadingInstance;

function processLoading(options = {} as LoadingOptions, stepMax = 3, stepTime = 1000) {
  const minStep = 1;
  const { text = loadingText } = options;

  function getLoadingText(process = 0) {
    return `${text} ${process}%`;
  }

  const loading = Loading({
    ...options,
    delay: 0,
    text: getLoadingText(0),
  }) as ProcessLoading;

  let process = 0;
  let timer: number | null = window.setInterval(() => {
    process += Math.floor(Math.random() * (stepMax - minStep + 1)) + minStep;
    if (process >= 100) {
      timer && clearInterval(timer);
      timer = null;
    }
    process = Math.min(process, 99);
    loading.updateText(getLoadingText(process));
  }, stepTime);

  loading.$updateText = (text: string, clearTimer = true) => {
    clearTimer && timer && clearInterval(timer);

    loading.updateText(text);
  };

  loading.$finished = async (text?: string) => {
    timer && clearInterval(timer);
    loading.updateText(text || getLoadingText(100));
    await sleep(200);
    loading.close();
  };
  return loading;
}

let loadingInstance: LoadingInstance | null = null;
function showLoading(options = {} as LoadingOptions) {
  loadingInstance = Loading(options);
}

function closeLoading() {
  loadingInstance?.close?.();
  loadingInstance = null;
}

export const $loading = { loading, showLoading, closeLoading, processLoading };
