import EventEmitter from '@/lib/EventEmitter';
import { getRawRoute } from '@/router/helper';
import type { RouteLocationNormalized } from 'vue-router';

const emitter = new EventEmitter();
const key = Symbol('logicRouter');
let currentRoute: RouteLocationNormalized;

export function onRouteChange(toRoute: RouteLocationNormalized) {
  const route = getRawRoute(toRoute);
  currentRoute = route;

  emitter.emit(key, route);
}

export function addListenerRouteChange(handler: (route: RouteLocationNormalized) => void, immediate = true) {
  emitter.on(key, handler);
  immediate && currentRoute && handler(currentRoute);
}

export function removeListenerRouteChange(handler?: (route: RouteLocationNormalized) => void) {
  emitter.off(key, handler);
}
