import { defineStore } from 'pinia';
import type { RouteRecordRaw } from 'vue-router';
import { resetRouter } from '@/router';
import { staticRoutes, dynamicRoutes } from '@/router/routes';
import { dynamicLoadPage } from '@/router/helper';
import { deepClone } from '@/utils';
import { $auth } from '@/globalApi';
import useUserStore from '@/store/modules/user';
import { buildMenuList, getMenuMap, type MenuItem } from '@/router/menu';

type PermissionState = {
  addRoutes: RouteRecordRaw[];
  removeRouteHandlers: Array<() => void>;
};
const usePermissionStore = defineStore('permission', {
  state: (): PermissionState => ({
    addRoutes: [],
    removeRouteHandlers: [],
  }),
  getters: {
    menuList(state): MenuItem[] {
      const routes = staticRoutes.concat(state.addRoutes);

      return buildMenuList(routes);
    },
    menuMap(): Record<string, MenuItem> {
      return getMenuMap(this.menuList || []) || {};
    },
  },
  actions: {
    setRemoveRouteHandlers(removeRouteHandlers: Array<() => void>) {
      this.removeRouteHandlers = [...removeRouteHandlers];
    },
    async generateRoutes() {
      const asyncRoutes = await this.getAsyncRoutes();

      const _dynamicRoutes = filterRoutes(deepClone(dynamicRoutes));
      const _asyncRoutes = filterRoutes(deepClone(asyncRoutes));
      const addRoutes = [..._dynamicRoutes, ..._asyncRoutes];

      this.addRoutes = addRoutes;

      return addRoutes;
    },
    async getAsyncRoutes() {
      const routes: RouteRecordRaw[] = [];
      return routes;
    },
    resetRouter() {
      resetRouter(this.removeRouteHandlers);
      this.addRoutes = [];
      this.removeRouteHandlers = [];
    },
  },
});

function filterRoutes(routes: RouteRecordRaw[]) {
  const _routes: RouteRecordRaw[] = [];
  const userStore = useUserStore();
  routes.forEach((route) => {
    if (route.meta?.permissions && !$auth.authPermission(route.meta.permissions, userStore.permissions)) {
      return;
    } else if (route.meta?.roles && !$auth.authRole(route.meta.roles, userStore.roles)) {
      return;
    }
    if (route.children) {
      route.children = filterRoutes(route.children);
      if (!route.children.length) return;
    }
    // @ts-ignore 异步路由动态加载页面组件
    typeof route.component === 'string' && (route.component = dynamicLoadPage(route.component));

    _routes.push(route);
  });

  return _routes;
}

export default usePermissionStore;
