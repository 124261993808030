/**
 * v-hasPermission 操作权限处理
 */
import type { Directive } from 'vue';
import { $auth } from '@/globalApi/auth';

const vHasPermission: Directive = {
  mounted(el, { value }) {
    if (value) {
      const isAccess = $auth.hasPermission(value);

      if (!isAccess) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('请设置操作权限标签值');
    }
  },
};

export default vHasPermission;
