<template>
  <span :class="['svg-icon', `svg-icon-${name}`, { spinning: spinning }, iconClass]">
    <component :is="icon" :width="width" :height="height" :style="{ animationDuration: `${animationDuration}s` }" />
    <slot>
      <span v-if="text">&nbsp;{{ text }}</span>
    </slot>
  </span>
</template>

<script setup lang="ts">
  import { defineAsyncComponent, shallowRef, watch } from 'vue';

  defineOptions({
    name: 'SvgIcon',
  });

  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '1em',
    },
    height: {
      type: String,
      default: '1em',
    },
    iconClass: {
      type: String,
      default: '',
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    animationDuration: {
      type: Number,
      default: 1.5,
    },
  });

  const icon = shallowRef();
  watch(
    () => props.name,
    (val) => {
      icon.value = defineAsyncComponent(() => import(`../../assets/icons/svg/${val}.svg`));
    },
    {
      immediate: true,
    },
  );
</script>

<style scoped lang="scss">
  .svg-icon {
    display: inline-block;
    line-height: 0;
    color: inherit;

    &[tabindex] {
      cursor: pointer;
    }

    &.spinning > svg {
      animation: svgIconSpinning 1s infinite linear;
    }

    svg,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  @keyframes svgIconSpinning {
    to {
      transform: rotate(360deg);
    }
  }
</style>
