import type { ErrorInfo } from './type';

export default class RequestError extends Error {
  errorCode: number;
  errorMessage: string;
  errorInfo: ErrorInfo;

  constructor(message: string, errorInfo: ErrorInfo) {
    super(message);
    this.errorCode = errorInfo.errorCode;
    this.errorMessage = errorInfo.errorMessage;
    this.errorInfo = errorInfo;
  }
}
