import { h as createElement } from 'vue';
import type { VueNode } from '@/types/vue';
import { Service as Alert, type ServiceOptions as AlertOptions } from '@/components/Modal/Alert';
import { Service as Confirm, type ServiceOptions as ConfirmOptions } from '@/components/Modal/Confirm';

function getTitle(title: VueNode | (() => VueNode)) {
  return typeof title === 'string'
    ? () => {
        return createElement(
          'div',
          {
            class: 'title',
            style: { textAlign: 'center' },
          },
          createElement('div', { style: { display: 'inline-block', textAlign: 'left' }, innerHTML: title }),
        );
      }
    : title;
}

function getContent(content: VueNode | (() => VueNode)) {
  return typeof content === 'string'
    ? () => {
        return createElement(
          'div',
          {
            class: 'content',
            style: { textAlign: 'center' },
          },
          createElement('div', { style: { display: 'inline-block', textAlign: 'left' }, innerHTML: content }),
        );
      }
    : content;
}

function alert(options: AlertOptions) {
  const props: AlertOptions = {
    ...options,
    title: getTitle(options.title),
    content: getContent(options.content),
    className: [options.className].join(' '),
  };

  return Alert(props);
}

function confirm(options: ConfirmOptions) {
  const props: ConfirmOptions = {
    ...options,
    title: getTitle(options.title),
    content: getContent(options.content),
    className: [options.className].join(' '),
  };

  return Confirm(props);
}

export const $modal = {
  alert,
  confirm,
};
