import { type App, createApp } from 'vue';
import type { VueNode } from '@/types/vue';
import Component from './index.vue';

export type ComponentProps = {
  open?: boolean;
  title?: string | (() => VueNode) | VueNode;
  content?: string | (() => VueNode) | VueNode;
  okText?: string;
  cancelText?: string;
  className?: string;
  onOk?: () => void;
  onCancel?: () => void;
};
export type ComponentInstance = InstanceType<typeof Component>;

export type ServiceOptions = Omit<ComponentProps, 'open'>;
export type ServiceInstance = { close: () => void };

const MARK_ROOT = '__global_component_root__';
const MARK_NODE = '__global_component_node__';

let container: HTMLElement & { [MARK_ROOT]?: App; [MARK_NODE]?: ComponentInstance };

export function Service(options?: Partial<ServiceOptions>): ServiceInstance {
  if (!container) {
    container = document.createElement('div');
    document.body.appendChild(container);
  }

  if (container[MARK_NODE]) {
    return container[MARK_NODE];
  }

  const currentProps: ComponentProps = { ...options, open: true };

  function close() {
    container[MARK_ROOT]?.unmount();

    delete container[MARK_ROOT];
    delete container[MARK_NODE];
  }

  function render(props: ComponentProps) {
    const root = createApp(Component, {
      ...props,
      onClosed: () => {
        close();
      },
    });
    const node = root.mount(container) as ComponentInstance;

    container[MARK_ROOT] = root;
    container[MARK_NODE] = node;
  }

  render(currentProps);

  return {
    close: () => {
      container[MARK_NODE]?.close();
    },
  };
}
