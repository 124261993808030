import type { RouteRecordRaw } from 'vue-router';
import BasicLayout from '@/layouts/BasicLayout.vue';
import { PAGE_NOT_FOND } from './basicRoutes';

// 静态路由
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: BasicLayout,
    redirect: '/create',
    meta: {
      hideInMenu: true,
    },
    children: [
      {
        path: '/index',
        name: 'Index',
        redirect: '/create',
        // component: () => import('@/pages/index.vue'),
        // meta: {
        //   title: '',
        //   icon: 'home',
        //   docTitle: 'A2E Photo - Turn Selfies to AI Portraits for Free',
        // },
      },
      {
        path: '/create',
        name: 'Create',
        component: () => import('@/pages/Create/index.vue'),
        meta: {
          title: 'Create',
        },
      },
      {
        path: '/result',
        name: 'Result',
        component: () => import('@/pages/Result.vue'),
        meta: {
          title: 'Result',
        },
      },
      {
        path: '/gallery',
        name: 'Gallery',
        component: () => import('@/pages/Gallery.vue'),
        meta: {
          title: 'Gallery',
        },
      },
    ],
  },
  {
    path: '/error',
    component: BasicLayout,
    meta: {
      title: 'error',
      icon: 'error',
    },
    children: [
      {
        path: '404',
        name: 'NotFond',
        component: () => import('@/pages/error/404.vue'),
        meta: {
          title: '404',
        },
      },
    ],
  },
  PAGE_NOT_FOND,
];

export default routes;
