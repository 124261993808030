import useUserStore from '@/store/modules/user';

export const TOKEN_KEY = 'access_token';
export const SUPER_ADMIN = 'admin';
export const SUPER_PERMISSION = '*:*:*';

function getToken(): string {
  return useUserStore().accessToken;

  // return localStorage.getItem(TOKEN_KEY) || '';
}

function setToken(token: string) {
  return localStorage.setItem(TOKEN_KEY, token);
}

function removeToken() {
  return localStorage.removeItem(TOKEN_KEY);
}

function authRole(role: string | string[], userRoles: string[]) {
  if (role && role.length) {
    const roles = Array.isArray(role) ? [...role] : [role];

    return userRoles.includes(SUPER_ADMIN) || userRoles.some((item) => roles.includes(item));
  } else {
    return true;
  }
}

function authPermission(permission: string | string[], userPermissions: string[]) {
  if (permission && permission.length) {
    const permissions = Array.isArray(permission) ? [...permission] : [permission];

    return userPermissions.includes(SUPER_PERMISSION) || userPermissions.some((item) => permissions.includes(item));
  } else {
    return true;
  }
}

// 验证用户是否具备某角色
function hasRole(role: string | string[]) {
  const userRoles = useUserStore().roles;

  return authRole(role, userRoles);
}

// 验证用户是否具备某权限
function hasPermission(permission: string | string[]) {
  const userPermissions = useUserStore().permissions;

  return authPermission(permission, userPermissions);
}

function refreshToken(): Promise<boolean> {
  const { doRefreshToken } = useUserStore();
  return doRefreshToken();
}

function goLogin() {
  const { goLogin } = useUserStore();
  goLogin();
}

export const $auth = {
  TOKEN_KEY,
  SUPER_ADMIN,
  SUPER_PERMISSION,
  getToken,
  setToken,
  removeToken,
  authRole,
  authPermission,
  hasRole,
  hasPermission,
  refreshToken,
  goLogin,
};
