import { deepClone } from '@/utils';
import type { RouteRecordRaw, RouteMeta } from 'vue-router';

export type MenuItem = {
  label: string;
  key: string;
  path: string;
  meta: RouteMeta;
  children?: MenuItem[];
};

export function buildMenuList(menuData: RouteRecordRaw[], basePath = '') {
  const menuList: MenuItem[] = [];
  menuData = deepClone(menuData);

  menuData.forEach((item) => {
    if (!item.meta || item.meta.hidden || !item.meta.title) return;

    const path = item.path.startsWith('/') ? item.path : `${basePath}/${item.path}`;
    const menuItem: MenuItem = {
      label: item.meta.title,
      key: path,
      path: path,
      meta: item.meta,
    };

    if (item.meta.hideChildrenInMenu) {
      item.redirect && menuList.push(menuItem);
      return;
    }

    if (item.children?.length) {
      const children = buildMenuList(item.children, menuItem.path);
      menuItem.children = children;
    }

    if (menuItem.meta?.hideInMenu) {
      menuItem.children?.length && menuList.push(...menuItem.children);
      return;
    }
    // 没有children或children数量大于0
    menuItem.children?.length !== 0 && menuList.push(menuItem);
  });

  return menuList;
}

export function getMenuMap(menuList: MenuItem[], menuMap: Record<string, MenuItem> = {}) {
  menuList.forEach((menuItem) => {
    menuMap[menuItem.path] = menuItem;
    if (menuItem.children?.length) {
      getMenuMap(menuItem.children, menuMap);
    }
  });
  return menuMap;
}
