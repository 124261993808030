// 是否是浏览器环境
export const isBrowser = typeof window !== 'undefined';

/**
 * 获取浏览器UA信息
 */
function getUserAgent() {
  return isBrowser ? window.navigator.userAgent : '';
}

/**
 * 浏览器类型
 * @param {string} [ua]
 */
export function browser(ua = '') {
  const userAgent = ua || getUserAgent();

  if (userAgent.includes('MicroMessenger')) {
    return 'WeChat';
  } else if (/M?QQBrowser/i.test(userAgent)) {
    return 'QQBrowser';
  } else if (/BytedanceWebview\/\w+ TTWebView\/\w+/i.test(userAgent)) {
    return 'Douyin';
  } else if (/BytedanceWebview\/\w+/i.test(userAgent)) {
    return 'TikTok';
  } else if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
    return 'UC';
  } else if (userAgent.includes(' OPR/')) {
    if (userAgent.includes('Mini')) {
      return 'Opera';
    }
    return 'Opera';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (userAgent.includes('SamsungBrowser/')) {
    return 'Samsung';
  } else if (userAgent.includes('FxiOS')) {
    return 'FirefoxIOS';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'IE';
  } else if (userAgent.includes('Edge') || userAgent.includes('Edg/')) {
    return 'Edge';
  } else if (userAgent.includes('CriOS')) {
    return 'ChromeIOS';
  } else if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (
    (isBrowser ? window.navigator.vendor : '').includes('Apple') ||
    /Version\/([\d.]+) (Mobile\/\w+ )?Safari/i.test(userAgent)
  ) {
    if (userAgent.includes('Mobile')) {
      return 'Safari';
    }
    return 'Safari';
  } else if (userAgent.includes('Gecko')) {
    return 'Mozilla';
  }

  return;
}

/**
 * 浏览器版本
 * @param {string} [ua]
 */
export function browserVersion(ua = '') {
  const userAgent = ua || getUserAgent();
  const _browser = browser(userAgent);
  const versionRegexs = {
    WeChat: /MicroMessenger\/(\d+(?:\.\d+)*)/i,
    QQBrowser: /QQBrowser\/(\d+(?:\.\d+)*)/i,
    Douyin: /app_version\/(\d+(?:\.\d+)*)/i,
    TikTok: /app_version\/(\d+(?:\.\d+)*)/i,
    UC: /(UCBrowser|UCWEB)\/(\d+(?:\.\d+)*)/,
    Opera: /(Opera|OPR)\/(\d+(?:\.\d+)*)/,
    BlackBerry: /BlackBerry (\d+(?:\.\d+)*)/,
    Samsung: /SamsungBrowser\/(\d+(?:\.\d+)*)/,
    FirefoxIOS: /FxiOS\/(\d+(?:\.\d+)*)/,
    Firefox: /Firefox\/(\d+(?:\.\d+)*)/,
    IE: /(rv:|MSIE )(\d+(?:\.\d+)*)/,
    Edge: /Edge?\/(\d+(?:\.\d+)*)/,
    ChromeIOS: /CriOS\/(\d+(?:\.\d+)*)/,
    Chrome: /Chrome\/(\d+(?:\.\d+)*)/,
    Safari: /Version\/(\d+(?:\.\d+)*)/,
    Mozilla: /rv:(\d+(?:\.\d+)*)/,
  };

  const regex = versionRegexs[_browser as keyof typeof versionRegexs];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return matches[1];
}

/**
 * 获取运行环境
 * @returns {string}
 */
export function getPlatform() {
  return isBrowser ? window.navigator.platform : '';
}

/**
 * 获取系统信息
 * @param {string} [ua]
 * @returns {string}
 */
export function getOS(ua = '') {
  const userAgent = ua || getUserAgent();
  let result = '';
  if (/Android/i.test(userAgent)) {
    result = 'android';
  } else if (/iPhone|iPad|iPod|iOS/i.test(userAgent)) {
    result = 'ios';
  } else if (/Mac OS X/.test(userAgent)) {
    result = 'mac';
  } else {
    result = 'pc';
  }
  return result;
}

/**
 * 是否是Android
 */
export function isAndroid(ua = '') {
  return getOS(ua) === 'android';
}

/**
 * 是否是IOS
 */
export function isIos(ua = '') {
  return getOS(ua) === 'ios' || getOS(ua) === 'ipados';
}

/**
 * 获取IOS版本号
 * @param {string} [ua]
 */
export function getIOSVersion(ua = '') {
  const userAgent = ua || getUserAgent();

  const match = userAgent.match(/^.*OS ([\d_]+) like.*$/i);
  return match ? match[1].replace(/_/g, '.') : '';
}

/**
 * 获取Android版本号
 * @param {string} [ua]
 */
export function getAndroidVersion(ua = '') {
  const userAgent = ua || getUserAgent();

  const match = userAgent.match(/Android\s([\d.]*)/i);
  return match ? match[1] : '';
}

export function isMobile(ua = ''): boolean {
  return isAndroid(ua) || isIos(ua);
}

export function isWeChat(ua = ''): boolean {
  const _browser = browser(ua);
  return _browser === 'WeChat';
}

export function isTikTok(ua = ''): boolean {
  const _browser = browser(ua);
  return _browser === 'TikTok';
}
