export type EventType = string | symbol;
export interface Handler {
  (...args: any[]): void;
  fn?: (...args: any[]) => void;
}

export default class EventEmitter {
  events: Record<EventType, Handler[]> = {};

  constructor() {}

  on(eventType: EventType, callback: Handler) {
    if (!this.events[eventType]) {
      this.events[eventType] = [];
    }
    this.events[eventType].push(callback);
  }

  once(eventType: EventType, callback: Handler) {
    const self = this;
    function wrapper(...args: any[]) {
      self.off(eventType, callback);
      callback.apply(callback, [...args]);
    }
    if (!this.events[eventType]) {
      this.events[eventType] = [];
    }

    wrapper.fn = callback;
    this.on(eventType, wrapper);
  }

  off(eventType: EventType, callback?: Handler) {
    if (!eventType) {
      this.events = {};
      return;
    }
    if (!this.events[eventType]) return;
    if (!callback) {
      delete this.events[eventType];
    } else {
      this.events[eventType] = this.events[eventType].filter((cb) => {
        return cb !== callback && cb.fn !== callback;
      });
    }
  }

  emit(eventType: EventType, ...args: any[]) {
    const callbackList = this.events[eventType] || [];
    callbackList.forEach(function (callback) {
      callback.apply(callback, [...args]);
    });
  }
}
