import GA4 from '@/lib/google/GA4';
import Mixpanel from '@/lib/Mixpanel';

class Tracker {
  ga4?: GA4;
  mixpanel?: Mixpanel;
  isInitialized: boolean = false;

  constructor() {}

  initialize() {
    if (this.isInitialized) {
      return;
    }

    if (__GTAG_ID__) {
      this.ga4 = GA4.getInstance(__GTAG_ID__);
    }
    if (__MIXPANEL_TOKEN__) {
      this.mixpanel = Mixpanel.getInstance(__MIXPANEL_TOKEN__, {
        debug: __DEBUG__,
        api_host: __MIXPANEL_PROXY_DOMAIN__,
      });
    }

    this.isInitialized = true;
  }

  register(properties: Record<string, any>, targetId?: string) {
    this.ga4?.register(properties, targetId);
    this.mixpanel?.register(properties);
  }

  login({ userId, userEmail = '' }: { userId: string; userEmail?: string }, streamId?: string) {
    this.ga4?.login({ userId, userEmail }, streamId);
    this.mixpanel?.login({ userId, userEmail });
  }
  logout(streamId?: string) {
    this.ga4?.logout(streamId);
    this.mixpanel?.logout();
  }

  sendEvent(event: string, data?: Record<string, any>) {
    this.mixpanel?.track(event, data);
    if (this.ga4?.isInitialized) {
      this.ga4.track(event, data);
    }
  }
}

export default new Tracker();
