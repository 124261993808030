import { getOS, getPlatform } from '@/utils';

/**
 * 解析error对象
 * @param error Error对象
 */
export function parseError(error: Error) {
  const res = {
    message: '',
    file: '',
    lineno: 0,
    colno: 0,
    stack: '',
  };
  try {
    error = error || {};
    const errorInfo = error.stack ? error.stack.match(/\((.+?):(\d+):(\d+)\)/) || [] : [];

    return {
      ...res,
      message: error.toString(),
      file: errorInfo[1] || '',
      lineno: parseInt(errorInfo[2]) || 0,
      colno: parseInt(errorInfo[3]) || 0,
      stack: error.stack ? error.stack.toString() : '',
    };
  } catch (e) {
    return res;
  }
}

export function errorReport(errorInfo: Record<string, any>, category = 'js_error') {
  const userAgent = window.navigator.userAgent;
  const pathName = window.location.pathname;
  const appName = __APP_NAME__;
  const appVersion = __APP_VERSION__;
  const env = __ENV__;
  const os = getOS();
  const platform = getPlatform();
  const userId = localStorage.getItem('uid') || '';

  const errorData = {
    os: os,
    platform: platform,
    env: env,
    user_agent: userAgent,
    page_url: encodeURI(document.location.href),
    target: pathName,
    appName: errorInfo.appName || appName,
    appVersion: errorInfo.appVersion || appVersion,
    user_id: errorInfo.userId || userId,
    message: errorInfo.message ?? '',
    detail: errorInfo.detail ?? '',
    category: category,
    error_code: errorInfo.error_code || '',
    error_message: errorInfo.error_message ?? (errorInfo.message || ''),
    timestamp: new Date().getTime(),
    extraData: '',
  };
  const extraData: Record<string, any> = {};
  Object.keys(errorInfo).forEach((prop) => {
    if (prop in errorData) {
      return;
    }
    extraData[prop] = errorInfo[prop];
  });

  errorData.extraData = JSON.stringify(extraData);
  if (typeof errorData.detail !== 'string') {
    errorData.detail = JSON.stringify(errorData.detail);
  }

  if (__DEBUG__) {
    console.log(errorData);
  }
}

/**
 * 错误信息捕获
 * @param {string} message 错误信息
 * @param {string} file 出错的文件
 * @param {number} lineno 出错代码的行号
 * @param {number} colno 出错代码的列号
 * @param {Object} error 错误的详细信息
 */
export function onError(message: string | Event, file = '', lineno = 0, colno = 0, error = {} as Error) {
  message = typeof message !== 'string' ? message.toString() : message;

  setTimeout(() => {
    try {
      const time = new Date().getTime();
      colno = colno || (window.event && window.event.errorCharacter) || 0;

      const detail = {
        file,
        lineno,
        colno,
        time,
        error: JSON.stringify(error),
        stack: '',
      };
      detail.stack =
        (error && error.stack) ||
        (function () {
          const fnList: string[] = [];
          let caller;

          try {
            caller = arguments.callee.caller.caller;
          } catch (e) {
            caller = null;
          }

          while (caller && fnList.length < 10) {
            const match = caller.toString().match(/function\s*([\w_$]+)?\s*\(/i);
            const functionName: string = (match && match[1]) || '[anonymous]';
            fnList.push(functionName);
            caller = caller.caller;
          }

          return fnList.join(', ');
        })();

      const errorInfo = {
        message,
        detail,
      };

      errorReport(errorInfo, 'js_error');
    } catch (e) {
      console.error(`onError: ${(e as Event).toString()}`);
    }
  }, 0);
  return false;
}

export function catchResourceError() {
  window.addEventListener(
    'error',
    (event) => {
      const target = event.target || event.srcElement;
      if (!target) return;

      const tagName = (target.localName || '').toLowerCase();
      const isResourceTag = ['script', 'link', 'img', 'audio', 'video'].indexOf(tagName) !== -1;

      // 过滤js 错误
      if (!isResourceTag) return false;

      const sourceUrl = target.src || target.href;
      const errorInfo = {
        message: 'resource load error',
        detail: { tagName, sourceUrl },
      };
      errorReport(errorInfo, 'resource_error');
    },
    true,
  );
}

export function catchUnhandledRejection() {
  window.addEventListener('unhandledrejection', (event) => {
    const error = event.reason || {};

    try {
      const errorInfo = parseError(error);

      onError(errorInfo.message, errorInfo.file, errorInfo.lineno, errorInfo.colno, error);
    } catch (e) {
      console.error(`unhandledrejection: ${error.toString()}`);
    }
  });
}

export function catchError() {
  window.onerror = onError;
  catchResourceError();
  catchUnhandledRejection();
}
