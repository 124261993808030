export function getClientSize() {
  const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

  return { clientWidth, clientHeight };
}

export type MediaQueryItem = {
  matchMedia: string;
  minWidth?: number;
  maxWidth?: number;
};

const mediaSize = {
  xs: {
    maxWidth: 575,
  },
  sm: {
    minWidth: 657,
    maxWidth: 767,
  },
  md: {
    minWidth: 768,
    maxWidth: 991,
  },
  lg: {
    minWidth: 992,
    maxWidth: 1199,
  },
  xl: {
    minWidth: 1200,
    maxWidth: 1599,
  },
  xxl: {
    minWidth: 1600,
  },
};

const mediaQueryEnum: { [k in MediaType]: MediaQueryItem } = {
  xs: {
    ...mediaSize.xs,
    matchMedia: `(max-width: ${mediaSize.xs.maxWidth}px)`,
  },
  sm: {
    ...mediaSize.sm,
    matchMedia: `(min-width: ${mediaSize.sm.minWidth}px) and (max-width: ${mediaSize.sm.maxWidth}px)`,
  },
  md: {
    ...mediaSize.md,
    matchMedia: `(min-width: ${mediaSize.md.minWidth}px) and (max-width: ${mediaSize.md.maxWidth}px)`,
  },
  lg: {
    ...mediaSize.lg,
    matchMedia: `(min-width: ${mediaSize.lg.minWidth}px) and (max-width: ${mediaSize.lg.maxWidth}px)`,
  },
  xl: {
    ...mediaSize.xl,
    matchMedia: `(min-width: ${mediaSize.xl.minWidth}px) and (max-width: ${mediaSize.xl.maxWidth}px)`,
  },
  xxl: {
    ...mediaSize.xxl,
    matchMedia: `(min-width: ${mediaSize.xxl.minWidth}px)`,
  },
};

type MediaQueryEnum = typeof mediaQueryEnum;

export function getScreenNameByClient(): MediaType {
  let mediaName: MediaType = 'md';
  // support ssr
  if (typeof window === 'undefined') {
    return mediaName;
  }
  const { clientWidth } = getClientSize();

  const mediaQueryKey = (Object.keys(mediaQueryEnum) as Array<keyof MediaQueryEnum>).find((key) => {
    const { maxWidth, minWidth } = mediaQueryEnum[key];

    if (!maxWidth && clientWidth >= minWidth!) return true;
    if (!minWidth && clientWidth <= maxWidth!) return true;
    if (clientWidth >= minWidth! && clientWidth <= maxWidth!) return true;

    return false;
  });

  mediaQueryKey && (mediaName = mediaQueryKey);
  return mediaName;
}

export function getScreenNameByMatchMedia(callback?: (mediaName: MediaType) => void): MediaType {
  let mediaName: MediaType = 'md';
  // support ssr
  if (typeof window === 'undefined') {
    callback?.(mediaName);
    return mediaName;
  }

  (Object.keys(mediaQueryEnum) as Array<keyof MediaQueryEnum>).forEach((key) => {
    const { matchMedia } = mediaQueryEnum[key];
    const query = window.matchMedia(matchMedia);

    if (query.matches) {
      mediaName = key;
      callback?.(mediaName);
    }
    query.onchange = (e) => {
      if (e.matches) {
        mediaName = key;
        callback?.(mediaName);
      }
    };
  });

  return mediaName;
}
