import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import useAppStore from './modules/app';
import useUserStore from './modules/user';
import usePermissionStore from './modules/permission';

const store = createPinia();
store.use(piniaPluginPersistedstate);

export { useAppStore, useUserStore, usePermissionStore };
export default store;
