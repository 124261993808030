import type { Router, RouteLocationRaw } from 'vue-router';
import { onRouteChange } from '@/logics/router';
import { useUserStore, usePermissionStore } from '@/store';
import { PAGE_NOT_FOND } from '@/router/routes';
import { joinQueryParams } from '@/utils';

const LOGIN_URL = __HOST_MAP__[location.hostname] || __HOST_MAP__.default;

export function setupRouterGuard(router: Router) {
  permissionGuard(router);

  pageGuard(router);
}

function pageGuard(router: Router) {
  router.beforeEach((to, _from) => {
    onRouteChange(to);
  });
}

function permissionGuard(router: Router) {
  const HOME_PATH = '/';
  const LOGIN_PATH = '/login';
  const whiteList = [LOGIN_PATH, /^\/redirect\//, /^\/error\//];

  function isInWhiteList(routePath: string) {
    return whiteList.some((item) => (typeof item === 'string' ? item === routePath : item.test(routePath)));
  }

  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const isLogin = userStore.isLogin;
    const { requireAuth = true } = to.meta || {};
    if (!isLogin) {
      if (!requireAuth || isInWhiteList(to.path)) {
        next();
      } else {
        const redirectURL = encodeURIComponent(location.origin + location.pathname + location.search);

        window.location.replace('https://login.a2e.ai?redirectURL=' + redirectURL);
        next(false);
      }

      return;
    }

    if (to.path === LOGIN_PATH) {
      const { redirect, redirectURL, ...query } = to.query;

      if (redirectURL) {
        window.location.replace(decodeURIComponent(redirectURL as string));

        next(false);
        return;
      }

      next({ path: redirect || HOME_PATH, query } as RouteLocationRaw);
    } else {
      if (!userStore.roles?.length) {
        try {
          await userStore.getUserInfo();

          const permissionStore = usePermissionStore();
          const addRoutes = await permissionStore.generateRoutes();
          const removeRouteHandlers: Array<() => void> = [];
          addRoutes.forEach((route) => {
            const parentName = route.meta?.parentName;
            const removeRouteHandler = parentName ? router.addRoute(parentName, route) : router.addRoute(route);
            removeRouteHandlers.push(removeRouteHandler);
          });
          permissionStore.setRemoveRouteHandlers(removeRouteHandlers);

          router.addRoute(PAGE_NOT_FOND);

          next();
        } catch (e) {
          console.log(e);

          await userStore.logout();

          // next({
          //   path: LOGIN_PATH,
          //   replace: true,
          //   query: {
          //     redirect: to.path,
          //     ...to.query,
          //   },
          // });

          const redirectURL = encodeURIComponent(location.origin + location.pathname + location.search);

          window.location.replace('https://login.a2e.ai?redirectURL=' + redirectURL);
          next(false);
        }
      } else {
        next();
      }
    }
  });
}
