import mixpanel, { type Persistence } from 'mixpanel-browser';
const DEVICE_ID_PREFIX = '$device:';

type InitOptions = {
  debug?: boolean;
  track_pageview?: boolean;
  persistence?: Persistence;
  ignore_dnt?: boolean;
  api_host?: string;
};

export default class Mixpanel {
  private static instance: Mixpanel | null = null;

  private constructor(token: string, options: InitOptions = {}) {
    if (!token) {
      throw new Error('Require mixpanel token');
    }

    this.initialize(token, options);
  }

  static getInstance(token: string, options: InitOptions = {}): Mixpanel {
    if (Mixpanel.instance === null) {
      Mixpanel.instance = new Mixpanel(token, options);
    }

    return Mixpanel.instance;
  }

  initialize(token: string, options: InitOptions = {}) {
    mixpanel.init(token, {
      track_pageview: true,
      ignore_dnt: true,
      ...options,
    });

    mixpanel.register({
      userAgent: window?.navigator.userAgent,
    });
  }

  login({ userId, userEmail = '', userName = '' }: { userId: string; userEmail?: string; userName?: string }) {
    mixpanel.identify(userId);

    this.register({
      // distinct_id: userId || this.getDefaultDistinctId(),
      // $user_id: userId || undefined,
      userEmail,
      userName,
    });
  }

  logout() {
    mixpanel.reset();

    // mixpanel.register({ distinct_id: this.getDefaultDistinctId() });
    // mixpanel.unregister('$user_id');
    // mixpanel.unregister('userEmail');
    // mixpanel.unregister('userName');
  }

  register(properties: Record<string, any>) {
    mixpanel.register({
      ...properties,
    });
  }

  track(event: string, data?: Record<string, any>) {
    mixpanel.track(event, {
      env: __ENV__,
      version: __GIT_COMMIT_ID__,
      ...data,
    });
  }

  getDefaultDistinctId() {
    return `${DEVICE_ID_PREFIX}${mixpanel.get_property('$device_id')}`;
  }
}
