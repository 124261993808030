import axios, { AxiosError } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import {
  ERROR_CODE,
  ERROR_MESSAGE,
  DEFAULT_ERROR_CODE,
  UNKNOWN_ERROR,
  SERVER_ERROR,
  HTTP_STATUS_ERROR,
  SERVICE_EXCEPTION,
  UNAUTHORIZED,
} from './constant';
import { $message } from '@/globalApi/message';
import { saveFile } from '@/utils/file';
import RequestError from './RequestError';
import type { LoadingOptions, ErrorInfo, RequestConfig, RequestOptions } from './type';

interface PendingTask {
  config: AxiosRequestConfig;
  resolve: Function;
}
let loadingIndex = 0;
let isRefreshingToken = false;
const taskQueue: PendingTask[] = [];

export default class Request {
  baseURL: string;
  timeout: number;
  headers: RawAxiosRequestHeaders;
  withCredentials: boolean;
  retryTimes: number;
  retryDelay: number;
  forceRetry: boolean;
  showErrorMessage: boolean;
  withToken: boolean;
  unauthorizedCode: Required<RequestConfig>['unauthorizedCode'];
  loadingOptions: Required<RequestConfig>['loadingOptions'];
  loadingHandler: Required<RequestConfig>['loadingHandler'];
  errorHandler: Required<RequestConfig>['errorHandler'];
  beforeRequestHandler: Required<RequestConfig>['beforeRequestHandler'];
  beforeResponseHandler: Required<RequestConfig>['beforeResponseHandler'];
  getTokenHandler: RequestConfig['getTokenHandler'];
  refreshTokenHandler: RequestConfig['refreshTokenHandler'];
  unauthorizedHandler: Required<RequestConfig>['unauthorizedHandler'];

  requestInstance: AxiosInstance;

  constructor(requestConfig: RequestConfig = {}) {
    const {
      baseURL = '',
      timeout = 5000,
      headers = {},
      withCredentials = true,
      retryTimes = 3,
      retryDelay = 0,
      forceRetry = false,
      showErrorMessage = true,
      withToken = true,
      unauthorizedCode = [401],
      loadingOptions = {
        show: true,
        type: 'default',
        text: '',
      },
      loadingHandler = (show, loadingOptions) => {
        console.log(show, loadingOptions);
      },
      errorHandler = (errorInfo, showErrorMessage) => {
        console.error(errorInfo);
      },
      beforeRequestHandler = (config) => {},
      beforeResponseHandler = (response, isError) => {},
      getTokenHandler,
      refreshTokenHandler,
      unauthorizedHandler,
    } = requestConfig;

    this.baseURL = baseURL;
    this.timeout = timeout;
    this.headers = headers;
    this.withCredentials = withCredentials;
    this.retryTimes = retryTimes;
    this.retryDelay = retryDelay;
    this.forceRetry = forceRetry;
    this.showErrorMessage = showErrorMessage;
    this.withToken = withToken;
    this.unauthorizedCode = unauthorizedCode;
    this.loadingOptions = loadingOptions;
    this.loadingHandler = loadingHandler;
    this.errorHandler = errorHandler;
    this.beforeRequestHandler = beforeRequestHandler;
    this.beforeResponseHandler = beforeResponseHandler;
    this.getTokenHandler = getTokenHandler;
    this.refreshTokenHandler = refreshTokenHandler;
    this.unauthorizedHandler = (errorInfo: ErrorInfo) => unauthorizedHandler?.(errorInfo);

    this.requestInstance = axios.create({
      baseURL: this.baseURL,
      timeout: this.timeout,
      withCredentials: this.withCredentials,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...this.headers,
      },
      showErrorMessage: this.showErrorMessage,
      retryTimes: this.retryTimes,
      retryDelay: this.retryDelay,
      retryCount: 0,
      forceRetry: this.forceRetry,
    });

    this.requestInstance.interceptors.request.use(
      (config) => {
        // 是否需要设置 token
        const { withToken = this.withToken } = config;
        if (withToken && this.getTokenHandler) {
          const token = this.getTokenHandler();
          token && (config.headers!.Authorization = `Bearer ${token}`);
        }

        this.loadingController(true, config.loadingOptions);
        this.beforeRequestHandler(config);
        return config;
      },
      (err) => {
        const config = (err.config || {}) as AxiosRequestConfig;
        const response = (err.response || {}) as AxiosResponse;
        this.loadingController(false, config.loadingOptions);

        if (err.errorInfo) {
          return Promise.reject(err);
        }

        const errorInfo = this.createErrorInfo(err, config, response);
        const error = new RequestError(errorInfo.errorMessage, errorInfo);

        $message.error(`(${errorInfo.errorCode}) ${errorInfo.errorMessage}`);

        this.errorHandler(errorInfo, showErrorMessage);

        return Promise.reject(error);
      },
    );

    this.requestInstance.interceptors.response.use(
      async (response) => {
        const config = response.config;
        const responseData = response.data;

        this.loadingController(false, config.loadingOptions);

        const isUnauthorized = this.isUnauthorized(response.status, responseData.code);
        if (isUnauthorized) {
          const refreshTokenRes = await this.autoRefreshToken(config);
          if (refreshTokenRes !== false) {
            return refreshTokenRes;
          }
        }

        this.beforeResponseHandler(response);
        if (isUnauthorized || responseData.code) {
          const code = responseData.code || (isUnauthorized ? UNAUTHORIZED : -1);
          const message = SERVICE_EXCEPTION[code] || responseData.message;
          const errorInfo = this.createErrorInfo(new Error(message), config, response);

          if (isUnauthorized) {
            this.unauthorizedHandler(errorInfo);
          }

          const { showErrorMessage = this.showErrorMessage } = config;
          showErrorMessage && $message.error(errorInfo.errorMessage);

          response.data = { code, message, data: responseData };
          return response;
        }

        return response;
      },
      async (err) => {
        const config = (err.config || {}) as AxiosRequestConfig;
        const response = (err.response || {}) as AxiosResponse;
        const responseData = response.data as any;

        this.loadingController(false, config.loadingOptions);

        const isUnauthorized = this.isUnauthorized(response.status, responseData?.code);
        if (isUnauthorized) {
          const refreshTokenRes = await this.autoRefreshToken(config);
          if (refreshTokenRes !== false) {
            return refreshTokenRes;
          }
        }

        this.beforeResponseHandler(response, true);
        if (isUnauthorized || (responseData && typeof responseData === 'object')) {
          const code = responseData?.code || (isUnauthorized ? UNAUTHORIZED : -1);
          const message = SERVICE_EXCEPTION[code] || responseData?.message;
          const errorInfo = this.createErrorInfo(new Error(message), config, response);

          if (isUnauthorized) {
            this.unauthorizedHandler(errorInfo);
          }

          const { showErrorMessage = this.showErrorMessage } = config;
          showErrorMessage && $message.error(errorInfo.errorMessage);

          response.data = { code, message, data: responseData };
          return Promise.resolve(response);
        }

        // 重试机制，默认只会重试 get 类型请求（网络异常或超时才会执行）
        if (
          (['get'].includes((config.method || '').toLowerCase()) || config.forceRetry) &&
          config.retryTimes! > config.retryCount! &&
          (this.isNetworkError(err) || this.isTimeout(err))
        ) {
          config.retryCount!++;
          if (__DEBUG__) {
            console.log(`retry ${config.retryCount} times`, config.url);
          }

          await new Promise((resolve) => {
            setTimeout(resolve, config.retryDelay);
          });
          return this.requestInstance(config);
        }

        const errorInfo = this.createErrorInfo(err, config, response);
        const error = new RequestError(errorInfo.errorMessage, errorInfo);

        const { showErrorMessage = this.showErrorMessage } = config;
        (showErrorMessage || this.isNetworkError(err) || this.isTimeout(err)) && $message.error(errorInfo.errorMessage);
        this.errorHandler(errorInfo, showErrorMessage);

        return Promise.reject(error);
      },
    );
  }

  isUnauthorized(statusCode: number, code: number | string) {
    return statusCode === 401 || this.unauthorizedCode.includes(code);
  }

  createErrorInfo(error: Error, config: AxiosRequestConfig, response: AxiosResponse) {
    const headers = { ...(config.headers || {}) };
    const responseData: Record<string, any> | null =
      typeof response?.data === 'string' ? { message: response.data } : response?.data || null;

    if (headers.Authorization) {
      // 隐藏token信息
      headers.Authorization = '***';
    }

    const statusCode = response.status || 0;

    let errorCode = DEFAULT_ERROR_CODE;
    let errorMessage = error.message || ERROR_MESSAGE[errorCode];

    if (statusCode > 0) {
      errorCode = responseData ? responseData.code || -1 : parseInt(`50${String(statusCode).padStart(3, '0')}`);
      errorMessage = SERVICE_EXCEPTION[errorCode] || responseData?.message || HTTP_STATUS_ERROR[statusCode];
      statusCode >= 500 ? SERVER_ERROR : HTTP_STATUS_ERROR[statusCode] || UNKNOWN_ERROR;
    } else {
      if (this.isTimeout(error)) {
        errorCode = ERROR_CODE.TIMEOUT;
        errorMessage = ERROR_MESSAGE[errorCode];
      } else if (this.isNetworkError(error)) {
        errorCode = ERROR_CODE.NETWORK_ERROR;
        errorMessage = ERROR_MESSAGE[errorCode];
      }
    }

    const errorInfo: ErrorInfo = {
      url: config.url || '',
      method: config.method || '',
      headers: headers,
      params: config.params ?? {},
      data: config.data ?? {},
      responseData: responseData,
      statusCode: statusCode,
      message: error.message || errorMessage,
      stack: error.stack?.toString() ?? '',
      errorCode: errorCode,
      errorMessage: errorMessage,
    };

    return errorInfo;
  }

  loadingController(show: boolean, _loadingOptions?: LoadingOptions) {
    const loadingOptions = Object.assign({}, this.loadingOptions, _loadingOptions);
    if (!loadingOptions.show) return;

    if (show) {
      if (++loadingIndex > 1) {
        return;
      }
    } else {
      if (--loadingIndex > 0) {
        return;
      }
      loadingIndex = 0;
    }

    this.loadingHandler(show, loadingOptions);
  }

  isNetworkError(error: Error | AxiosError) {
    return (error as AxiosError).code === 'ERR_NETWORK' || error.message.toLowerCase().includes('network error');
  }

  isTimeout(error: Error | AxiosError) {
    return (error as AxiosError).code === 'ECONNABORTED' && error.message.toLowerCase().includes('timeout');
  }

  async autoRefreshToken(config: AxiosRequestConfig) {
    if (this.refreshTokenHandler) {
      if (isRefreshingToken) {
        return new Promise<AxiosResponse>((resolve) => {
          taskQueue.push({
            config,
            resolve,
          });
        });
      }
      isRefreshingToken = true;
      try {
        const res = await this.refreshTokenHandler(config);

        if (res) {
          let pendingTask: PendingTask | undefined;
          while ((pendingTask = taskQueue.shift())) {
            const { config, resolve } = pendingTask;
            resolve(this.requestInstance(config));
          }

          return this.requestInstance(config);
        }
      } catch (e) {
        console.error(e);
      }
      isRefreshingToken = false;
      return false;
    }
    return false;
  }

  request<Result = any, Data = any>(config: AxiosRequestConfig) {
    return this.requestInstance.request<Result, AxiosResponse<Result>, Data>(config).then((response) => {
      return response.data;
    });
  }

  get<Result = any, Data = any>(url: string, requestOptions?: RequestOptions) {
    return this.request<Result, Data>({
      url,
      method: 'GET',
      ...requestOptions,
    });
  }

  post<Result = any, Data = any>(url: string, data = {}, requestOptions?: RequestOptions) {
    return this.request<Result, Data>({
      url,
      method: 'POST',
      data,
      ...requestOptions,
    });
  }

  put<Result = any, Data = any>(url: string, data = {}, requestOptions?: RequestOptions) {
    return this.request<Result, Data>({
      url,
      method: 'PUT',
      data,
      ...requestOptions,
    });
  }

  patch<Result = any, Data = any>(url: string, data = {}, requestOptions?: RequestOptions) {
    return this.request<Result, Data>({
      url,
      method: 'PATCH',
      data,
      ...requestOptions,
    });
  }

  delete<Result = any, Data = any>(url: string, requestOptions?: RequestOptions) {
    return this.request<Result, Data>({
      url,
      method: 'DELETE',
      ...requestOptions,
    });
  }
  async download(url: string, _filename = '', requestOptions?: RequestOptions) {
    // 后端配置 response headers
    // Content-type: application/octet-stream
    // Content-Disposition: attachment; filename="file.zip"

    const res = await this.requestInstance.request({
      url,
      method: 'get',
      responseType: 'blob',
      withCredentials: false,
      ...requestOptions,
    });

    let filename = _filename;
    if (!filename) {
      const disposition = res.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    if (!filename) {
      filename = url.slice(url.lastIndexOf('/') + 1);
      if (filename.indexOf('.') === -1) filename = '';
    }

    const blob = new Blob([res.data]);
    saveFile(blob, filename);
  }
}
