import { defineStore } from 'pinia';
import { Base64 } from 'js-base64';
import * as api from '@/apis';
import defaultAvatar from '@/assets/icons/svg/user.svg?url';
import { $auth, $modal, $message } from '@/globalApi';
import { sleep, getQueryParams, getSearchParams, joinQueryParams, randomWithPercentList, isMail } from '@/utils';
import Tracker from '@/lib/Tracker';
import { isTypeWithProperties } from '@/types';

export type LoginInfo = { email: string; password: string };
type RegisterInfo = LoginInfo & { redirectURL?: string };

type LoginRes = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at: number;
};

type LoginState = {
  accessToken: string;
  refreshToken: string;
  expiresIn?: number;
  expiresAt?: number;
};

interface UserInfo {
  id: string;
  email: string;
  phone?: string;
  avatar?: string;
}

interface State {
  accessToken: string;
  refreshToken: string;
  tokenExpiresIn: number;
  tokenExpiresAt: number;
  nonceId: string;
  userInfo: UserInfo;
  roles: string[];
  permissions: string[];
  paymentTest: boolean;
}

function initialState() {
  const initialState: State = {
    accessToken: '',
    refreshToken: '',
    tokenExpiresIn: 0,
    tokenExpiresAt: 0,
    nonceId: '',
    userInfo: {
      id: '',
      email: '',
      phone: '',
      avatar: defaultAvatar,
    },
    roles: [],
    permissions: [],
    paymentTest: false,
  };
  return initialState;
}

const useUserStore = defineStore('user', {
  persist: {
    paths: ['accessToken', 'refreshToken', 'tokenExpiresIn', 'tokenExpiresAt', 'nonceId'],
    // storage: window?.sessionStorage,
  },
  state: (): State => ({
    ...initialState(),
  }),
  actions: {
    async bootstrap() {
      const {
        redirectURL: _redirectURL,
        nonceId: _nonceId,
        userEmail,
        ...otherQueryParams
      } = getQueryParams<{
        redirectURL?: string;
        userEmail?: string;
        nonceId?: string;
      }>(window.location.href);

      const nonceId = _nonceId || userEmail;

      const redirectURL = _redirectURL ? decodeURIComponent(_redirectURL) : '';
      const newUrl = joinQueryParams(window.location.origin + window.location.pathname, otherQueryParams);

      if (nonceId && nonceId !== this.nonceId) {
        // this.nonceId && (await this.logout(false));
        await this.login(nonceId);
        window.location.replace(redirectURL || newUrl);
        return;
      }

      {
        const data = getSearchParams(window.location.hash);
        const { access_token, refresh_token } = data;
        const expires_in = Number(data.expires_in) || 0;
        const expires_at = Number(data.expires_at) || 0;
        const isValid = expires_at ? Math.ceil(new Date().getTime() / 1000) <= expires_at : true;

        if (access_token) {
          if (isValid) {
            await this.login({ access_token, refresh_token, expires_in, expires_at }, redirectURL || newUrl);

            window.location.replace(redirectURL || newUrl);
          } else {
            window.location.replace(newUrl);
          }
          return;
        }
      }

      if (this.isLogin && this.accessToken) {
        await this.getUserInfo();
      }

      // MARK - Google Ga4 数据 需要每次重新设置
      {
        if (!this.isLogin) return;

        Tracker.ga4?.login({ userId: this.userEmail, userEmail: this.userEmail });
      }

      let paymentTest: string | number = localStorage.getItem('paymentTest') as string;
      if (!paymentTest) {
        paymentTest = randomWithPercentList([1, 2], [10, 90]);
        localStorage.setItem('paymentTest', String(paymentTest));
      }
      this.paymentTest = Number(paymentTest) === 1;
    },
    async setLoginState({ accessToken, refreshToken, expiresIn = 0, expiresAt = 0 }: LoginState) {
      const tokenExpiresAt = expiresAt || (expiresIn ? Math.ceil(new Date().getTime() / 1000) + expiresIn : 0);

      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.tokenExpiresIn = expiresIn;
      this.tokenExpiresAt = tokenExpiresAt;

      // $auth.setToken(accessToken);
      await sleep(0);
    },
    setUserInfoState(user: UserInfo) {
      const userInfo = {
        id: user.id || '',
        email: user.email || '',
        phone: user.phone || '',
        avatar: user.avatar || defaultAvatar,
      };
      this.userInfo = userInfo;
    },
    reset() {
      Object.assign(this, initialState());

      // $auth.removeToken();
    },
    async register(registerInfo: RegisterInfo) {
      const { email, password } = registerInfo;

      try {
        const res = await api.register({ email, password });
        const { code, message } = res;
        if (code) {
          throw new Error(message);
        }

        $message.success('Register success');
      } catch (error) {
        $message.error((error as Error).message);
        throw error;
      }
    },
    async login(loginInfo: LoginInfo | LoginRes | string, reloadPage: boolean | string = false) {
      try {
        let res: { code: number | string; message: string; data: any } | undefined;

        if (typeof loginInfo === 'string') {
          const nonceId = loginInfo;

          this.nonceId = nonceId;
          await this.setLoginState({
            accessToken: '',
            refreshToken: '',
            expiresIn: 0,
            expiresAt: 0,
          });
        } else if (isTypeWithProperties<LoginRes>(loginInfo, ['access_token'])) {
          res = { code: 0, message: '', data: { ...loginInfo } };
        } else {
          const email = loginInfo.email.trim();
          const password = loginInfo.password;
          res = await api.login({ email: email, password });
        }

        if (res) {
          const { code, message, data } = res;
          if (code) {
            throw new Error(message);
          }

          $message.success('Login success');

          this.nonceId = '';
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            expires_in: expiresIn = 0,
            expires_at: expiresAt = 0,
          } = data;
          await this.setLoginState({
            accessToken,
            refreshToken,
            expiresIn,
            expiresAt,
          });
        }
        const { email: userEmail } = this.accessToken
          ? JSON.parse(Base64.decode(this.accessToken.split('.')[1]))
          : { email: this.nonceId };

        Tracker.login({ userId: userEmail, userEmail: userEmail });

        if (reloadPage) {
          reloadPage === true ? window.location.reload() : window.location.replace(decodeURIComponent(reloadPage));
          return;
        } else {
          if (this.accessToken) {
            await this.getUserInfo();
          }
        }
      } catch (error) {
        $message.error((error as Error).message);
        throw error;
      }
    },
    async doRefreshToken() {
      try {
        if (this.refreshToken) {
          const res = await api.refreshToken(this.refreshToken);
          const { code, message, data } = res;
          if (code) {
            throw new Error(message);
          }

          {
            const {
              access_token: accessToken,
              refresh_token: refreshToken,
              expires_in: expiresIn = 0,
              expires_at: expiresAt = 0,
            } = data;
            await this.setLoginState({
              accessToken,
              refreshToken,
              expiresIn,
              expiresAt,
            });
          }

          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    async getUserInfo() {
      try {
        const res = this.accessToken ? await api.getUserInfo() : ({} as any);
        const { code = 0, message = '', data: user = {} } = res;
        if (code) {
          throw new Error(message);
        }

        // 接口返回角色、权限数组
        const userRoles: string[] = [];
        const userPermissions: string[] = [];

        this.setUserInfoState(user);

        const roles: string[] = [];
        const permissions: string[] = [];

        (userRoles || []).forEach((role) => {
          role && roles.push(role);
        });
        (userPermissions || []).forEach((permission) => {
          permission && permissions.push(permission);
        });

        // 没有角色、权限时设置一个默认值
        !roles.length && roles.push('DEFAULT_ROLE');
        !permissions.length && permissions.push('DEFAULT_PERMISSION');

        this.roles = roles;
        this.permissions = permissions;

        return user;
      } catch (error) {
        $message.error((error as Error).message);
        throw error;
      }
    },
    async logout(refreshPage = true) {
      try {
        if (this.accessToken) {
          await api.logout();
        }

        this.reset();

        Tracker.logout();

        if (refreshPage) {
          window.location.reload();
        }
      } catch (error) {
        $message.error((error as Error).message);
        return;
      }
    },
    async goLogin() {
      if (this.accessToken) {
        // await new Promise<void>((resolve) => {
        //   $modal.alert({
        //     content: 'Unauthorized. Please login again', // 登录状态已过期，请重新登录,
        //     onOk: async () => {
        //       await this.logout(false);
        //       resolve();
        //     },
        //   });
        // });

        await this.logout(false);

        const redirectURL = encodeURIComponent(
          window.location.origin + window.location.pathname + window.location.search,
        );
        window.location.replace('https://login.a2e.ai?redirectURL=' + redirectURL);
      } else {
        const redirectURL = encodeURIComponent(
          window.location.origin + window.location.pathname + window.location.search,
        );
        window.location.replace('https://login.a2e.ai?redirectURL=' + redirectURL);
      }

      // const redirectURL = encodeURIComponent(location.origin + location.pathname + location.search);
      // location.replace(`${location.origin}/login?redirectURL=${redirectURL}`);
    },

    setNonceId(nonceId: string) {
      sessionStorage.setItem('nonceId', nonceId);
      this.nonceId = nonceId;
    },
    getNonceId() {
      return sessionStorage.getItem('nonceId') || sessionStorage.getItem('userEmail') || '';
    },
    quicklyGenerate(skin: string): boolean {
      return true;

      const quicklyGenerate = !isMail(this.userEmail) || ['European', 'African', 'South Asian'].indexOf(skin) !== -1;

      return quicklyGenerate;
    },
  },
  getters: {
    uid(state) {
      return state.userInfo.id;
    },
    userEmail(state) {
      return state.userInfo.email || state.nonceId || '';
    },
    avatar(state) {
      return state.userInfo.avatar;
    },
    isLogin(state) {
      return !!(state.accessToken || state.nonceId);
    },
  },
});

export default useUserStore;
