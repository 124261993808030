import { createWebHistory, createRouter } from 'vue-router';
import { staticRoutes } from '@/router/routes';
import { setupRouterGuard } from '@/router/guard';

function _createRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes: staticRoutes,
    strict: true,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { left: 0, top: 0 };
      }
    },
  });

  setupRouterGuard(router);

  return router;
}
const router = _createRouter();

export function resetRouter(removeRouteHandlers: Array<() => void>) {
  removeRouteHandlers.forEach((removeRouteHandler) => {
    removeRouteHandler();
  });
}

export default router;
