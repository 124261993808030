/**
 * v-hasRole 角色权限处理
 */
import type { Directive } from 'vue';
import { $auth } from '@/globalApi/auth';

const vHasRole: Directive = {
  mounted(el, { value }) {
    if (value) {
      const isAccess = $auth.hasRole(value);

      if (!isAccess) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('请设置角色权限标签值');
    }
  },
};

export default vHasRole;
