type InitOptions = {
  gtagUrl?: string;
  gaOptions?: GaOptions;
  gtagOptions?: GtagOptions;
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/config?hl=zh-cn
export type GaOptions = {
  allow_google_signals?: boolean;
  allow_ad_personalization_signals?: boolean;
  campaign_content?: string;
  campaign_id?: string;
  campaign_medium?: string;
  campaign_name?: string;
  campaign_source?: string;
  campaign_term?: string;
  client_id?: string;
  content_group?: string;
  cookie_domain?: string;
  cookie_expires?: number;
  cookie_flags?: string;
  cookie_path?: string;
  cookie_prefix?: string;
  cookie_update?: boolean;
  language?: string;
  page_location?: string;
  page_referrer?: string;
  page_title?: string;
  send_page_view?: boolean;
  screen_resolution?: string;
  user_id?: string;
  user_properties?: Record<string, string | number>;
};

export type GtagOptions = Record<string, any>;

export default class GA4 {
  isInitialized: boolean = false;
  _hasLoadedGA: boolean = false;
  _currentMeasurementId = '';
  private static instance: GA4 | null = null;

  private constructor(GA_MEASUREMENT_ID: string, options: InitOptions = {}) {
    if (!GA_MEASUREMENT_ID) {
      throw new Error('Require GA_MEASUREMENT_ID');
    }

    this.initialize(GA_MEASUREMENT_ID, options);
  }

  static getInstance(GA_MEASUREMENT_ID: string, options: InitOptions = {}): GA4 {
    if (GA4.instance === null) {
      GA4.instance = new GA4(GA_MEASUREMENT_ID, options);
    }

    return GA4.instance;
  }

  initialize(GA_MEASUREMENT_ID: string, options: InitOptions = {}) {
    this._currentMeasurementId = GA_MEASUREMENT_ID;

    const { gaOptions, gtagOptions, gtagUrl } = options;

    this._loadGA(this._currentMeasurementId, gtagUrl);

    if (!this.isInitialized) {
      if (!window.dataLayer.find((item: any) => item[0] === 'js')) {
        this.gtag('js', new Date());
      }

      const mergedGtagOptions = {
        ...gaOptions,
        ...gtagOptions,
      };

      if (!window.dataLayer.find((item: any) => item[0] === 'config' && item[1] === this._currentMeasurementId)) {
        this.config(mergedGtagOptions);
      }
    }
    this.isInitialized = true;
  }

  _loadGA(GA_MEASUREMENT_ID: string, gtagUrl = 'https://www.googletagmanager.com/gtag/js') {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    if (!this._hasLoadedGA) {
      if (!window.gtag) {
        // Global Site Tag (gtag.js) - Google Analytics
        const script = document.createElement('script');
        script.async = true;
        script.src = `${gtagUrl}?id=${GA_MEASUREMENT_ID}`;

        document.body.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          // eslint-disable-next-line
          window.dataLayer.push(arguments);
        };
      }

      this._hasLoadedGA = true;
    }
  }

  gtag(...args: any[]) {
    window.gtag(...args);
  }

  config(fieldObject: Record<string, any> | null, targetId?: string) {
    if (fieldObject && Object.keys(fieldObject).length) {
      this.gtag('config', targetId || this._currentMeasurementId, fieldObject);
    } else {
      this.gtag('config', targetId || this._currentMeasurementId);
    }
  }

  // 经测试只能设置Google预置属性
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/config?hl=zh-cn
  set(fieldObject: Record<string, any>) {
    this.gtag('set', fieldObject);
  }
  get(fieldName: string, callback?: (value: any) => void, targetId?: string) {
    this.gtag('get', targetId || this._currentMeasurementId, fieldName, callback);
  }

  track(event: string, data?: Record<string, any>) {
    if (!this.isInitialized) {
      console.warn('GA4 is not initialized');
      return;
    }
    this.gtag('event', event, data);
  }

  login(
    { userId, userEmail = '', userName = '' }: { userId: string | number; userEmail?: string; userName?: string },
    streamId?: string,
  ) {
    if (streamId) {
      this.gtag('set', streamId, { user_id: userId });
    } else {
      this.gtag('set', 'user_id', userId);
    }

    this.register({ userEmail, userName }, streamId);
  }

  logout(streamId?: string) {
    if (streamId) {
      this.gtag('set', streamId, { user_id: '' });
    } else {
      this.gtag('set', 'user_id', '');
    }
    this.register({ userEmail: '', userName: '' }, streamId);
  }

  register(properties: Record<string, any>, targetId?: string) {
    this.config(properties, targetId);
  }
}
